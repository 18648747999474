import './style.scss';
import React, {useEffect, useMemo, useState} from 'react';
import AButton from '../../atoms/button';
import cn from "classnames";
import ReactDOM from "react-dom";
// import { useCookies } from 'react-cookie';

export type NotificationType = {
    title?: string,
    text?: string,
    button?: string,
    cookies?: any,
    onClose?: () => void
    time_view?: number
    type?: "info" | "error"
}

const messagesRoot = document.getElementById("messages");

const Notification = (props: NotificationType) => {
    const [isOpen, setOpen] = useState(props.cookies ? !props.cookies.notification : true);
    const divEl: HTMLDivElement | any = useMemo(() => {
        const divEl = document.createElement("div");
        divEl.classList.add("notification-wrapper");
        
        return divEl;
    }, []);

    const closeNotification = () => {
        if (props.onClose) {
            props.onClose()
        }
        setOpen(false);
    }

    useEffect(() => {
        if (!divEl) return;

        messagesRoot?.appendChild(divEl);
        return () => messagesRoot?.removeChild(divEl);
    }, [divEl]);

    if (!isOpen) return null;

    const renderMessage = (
        <div className={cn('notification', {
            "notification--error": props.type === "error",
        })}>
            <button className='notification__close' onClick={() => closeNotification()} aria-label='close button'/>
            {props.title && typeof props.title === 'string' && <p className='caption-5 notification__title'>{props.title}</p>}
            {props.text && typeof props.text === 'string' && <p className='text-2 notification__text'>{props.text}</p>}
            {props.button && typeof props.button === 'string' && <AButton className='notification__button' text={props.button} onClick={() => closeNotification()} aria-label='close button'/>}
        </div>
    );

    return ReactDOM.createPortal(
        renderMessage,
        divEl,
    )
};

Notification.defaultProps = {
    type: "info",
}

export default Notification;
