import './App.scss';

import React, {useEffect, useState, useContext, ReactElement, useCallback} from 'react';
import { BrowserRouter as Router, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Main from './pages/main';
import Contacts from './pages/contacts';
import Page404 from './pages/page-404';
import Page500 from './pages/page-500';
import PageServices from './pages/services';
import Works from './pages/works';
import Blogs from './pages/blogs';
import Layout from './layout';
import DetailService from "./pages/detail-service";
import DetailWorks from "./pages/detail-works";
import DetailBlogs from "./pages/detail-blogs"
import StaticPage from "./pages/static-page"
import Notification from './components/molecules/notification';
import { useCookies } from 'react-cookie';
import ScrollToTop from './assets/scripts/scroll-to-top';
import Agency from './pages/agency';
import { NotFoundContext } from './context/NotFoundContext';
import ScrollTrigger from "gsap/ScrollTrigger";
import TransitionComponent from "./assets/TransitionComponent";

const RoutesWrp = () => {
  const [isNotFound, setContext] = useContext(NotFoundContext);
  const location = useLocation();
  const staticsLinks = (window as any)?.INIT_DATA?.staticsLinks;
  const isStaticsLinksNotEmpty = staticsLinks && Object.keys(staticsLinks).length ? true : false;

  useEffect(() => {
    setContext(false);
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1000);
  }, [location]);

  const matchMedia = window.matchMedia('(min-width: 768px)').matches;

  const addRouteElement = useCallback((
    children: ReactElement,
    options = { hideFooter: false },
  ) => {
    if (isNotFound) return <Page404 />;

    return matchMedia
      ? <Layout hideFooter={options.hideFooter}>
          <TransitionComponent>
            {children}
          </TransitionComponent>
        </Layout>
      : <Layout hideFooter={options.hideFooter}>{children}</Layout>;
  }, [isNotFound, matchMedia]);

  return (
      <Routes>
        <Route path={'/'} element={addRouteElement(<Main/>, { hideFooter: true })}/>
        <Route path={'*'} element={<Page404/>}/>
        <Route path={'500'} element={<Page500/>}/>
        <Route path={'/services/'} element={addRouteElement(<Outlet />)}>
          <Route index element={<PageServices />} />
          <Route path=":service_id/" element={<DetailService />} />
        </Route>
        <Route path={'/blogs'} element={addRouteElement(<Outlet />)}>
          <Route index element={<Blogs />} />
          <Route path=":article_id/" element={<DetailBlogs />} />
        </Route>
        <Route path={'/contacts/'} element={addRouteElement(<Contacts/>)}/>
        <Route path={'/works/'} element={addRouteElement(<Outlet />)}>
          <Route index element={<Works />} />
          <Route path=":works_id/" element={<DetailWorks />} />
        </Route>
        <Route path='/agency/' element={addRouteElement(<Agency/>, { hideFooter: true })}/>
        {isStaticsLinksNotEmpty && (
          <Route path='/content/:static_code/' element={addRouteElement(<StaticPage />)} />
        )}
      </Routes>
  )
}

function App() {
  const [cookies, setCookie] = useCookies(['notification']);
  const [context, setContext] = useState(false);
  // @ts-ignore
  const data = window.INIT_DATA;

  return (
    <NotFoundContext.Provider value={[context, setContext]}>
      <Router>
          <ScrollToTop />
          <RoutesWrp />
      </Router>
      { data && data.cookies && !cookies.notification && <Notification
        cookies={cookies}
        onClose={() => {
          setCookie('notification', true);
        }}
        {...data.cookies}
      /> }
    </NotFoundContext.Provider>
  );
}

export default App;
