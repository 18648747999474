import './style.scss';
import React, {useContext, useState, useEffect, ChangeEvent, useRef } from 'react';
import { useSearchParams } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import gsap, {ScrollTrigger} from 'gsap/all';
import FilterTag from '../../components/atoms/filter-tag';
import Sprite from "../../assets/scripts/Sprite";
import { parallaxEffect } from '../../assets/scripts/animations';
import { transformCard, clearTransform, enterFlip } from '../../assets/scripts/animations';
import useInfiniteScroll from '../../assets/scripts/useInfiniteScroll';
import { request } from "../../api/api";
import { SEOTypes } from "../../types";
import useChangeSEO from "../../hooks/useChangeSEO";
import { NotFoundContext } from '../../context/NotFoundContext';
import { ImageType } from "../../components/atoms/image";
import { isEqual } from "lodash";
import fixedUrlTo from "../../assets/scripts/fixedUrlTo";

gsap.registerPlugin(ScrollTrigger);

const CardEvent = React.lazy(() => import('../../components/molecules/card-event'));
const urlByStages = process.env.REACT_APP_STAGE === "integration" ? "getPageBlogs/" : '/ajax/blogs.json';

const Blogs = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [ajaxData, setAjaxData] = useState<any>({});
    const [idList, setIdList] = useState<any>([]);
    const [isLoading, setLoading] = useState<any>(false);
    const [items, setItems] = useState<object[]>([]);

    const [context, setContext] = useContext(NotFoundContext);
    const fetchMoreItems = () => {
        setLoading(true);

        request({
            method: "GET",
            url: urlByStages,
            params: {
                lang: process.env.REACT_APP_LANG,
                count: items.length,
                id: idList.join(',')
            }
        }).then(({ data }) => {
            if (data.items?.length) {
                setItems(prevState => [...prevState, ...data.items]);

                if (items.length % data.items.length !== 0) {
                    setScrollOn(false);
                }
            }

            setLoading(false);
        });
    }

    const [setScrollOn] = useInfiniteScroll(fetchMoreItems);

    useEffect(() => {
        const ids = searchParams.get('id') ? searchParams.get('id')?.split(',') : [];

        if (!isEqual(idList, ids)) {
            setIdList(ids);
        }
        setScrollOn(false);
    }, [searchParams]);

    useEffect(() => {
        request({
            method: "GET",
            url: urlByStages,
            params: {
                lang: process.env.REACT_APP_LANG,
                id: idList ? idList.join(',') : [],
            },
        }).then(({ data }) => {
            setAjaxData(data);
            setScrollOn(true);

            if (data?.filter) {
                const idsArray = searchParams.get('id')?.split(',') || [];

                if (idsArray?.length) {
                    const filterTags = data.filter.map((obj: any) => obj?.name ? obj.name : '');
                    const idsArrayCopy = idsArray.filter((id) => filterTags.includes(id));

                    if (idsArray.length !== idsArrayCopy.length) {
                        setSearchParams(idsArrayCopy?.length ? {
                            id: idsArrayCopy.join(','),
                        } : {});
                        return
                    }
                }
            }

            if (data.items?.length) {
                setItems(data.items);
            } else {
                setItems([]);
            }
        }).catch(() => {
            setContext(true);
        });
    }, [idList])

    useEffect(() => {
        animation();
    }, [items])

    const card_release = useRef<HTMLElement | null>(null);

    const animation = () => {
        enterFlip('.blogs__card:not(.js-init-card)', card_release);
        const items = Array.from(document.querySelectorAll('.blogs .blogs__cards .blogs__card'));
        items.forEach(item => item.classList.add('js-init-card'));
    }

    const onChange = (e: ChangeEvent) => {
        let idsArr = [];
        if (idList.includes((e.target as HTMLInputElement).name)) {
            idsArr = idList.filter((item: string) => item !== (e.target as HTMLInputElement).name);
        } else {
            idsArr = [...idList, (e.target as HTMLInputElement).name];
        }

        setSearchParams(idsArr.length ? {
            id: idsArr.join(','),
        } : {});
    }

    const setAnimate = (elem: string) => {
        parallaxEffect(elem);
    }

    const seoData: Partial<SEOTypes> = {
        title: ajaxData.title,
        ...ajaxData.seo,
    }

    useChangeSEO({data: seoData});

    return (
        <section className='blogs'>
            <span className='gradient-radial gradient-radial--red blogs__gradient blogs__gradient--tl'></span>
            <span className='gradient-radial gradient-radial--blue blogs__gradient blogs__gradient--tr'></span>
            {ajaxData.title && typeof ajaxData.title === 'string' && (
                <h1 className='blogs__heading caption-7'>{ajaxData.title}</h1>
            )}
            <div className='blogs__filter-tags'>
                {Array.isArray(ajaxData.filter) && (
                    ajaxData.filter.map((tag: any, index: number) => (
                        <FilterTag key={`filter-tag-${index}`} {...tag} checked={(idList.includes(tag.name))} onChange={(e) => onChange(e)} />
                    ))
                )}
            </div>
            <section className='blogs__cards' ref={card_release}>
                {Array.isArray(items) && (
                    items.map((card: any, index: number) => {
                        const images: ImageType["sources"] = [
                            {
                                media: "(min-width: 0)",
                                srcset: card?.images?.medium || ""
                            }
                        ]
                        if ((index + 1) % 3 === 0) {
                            images.unshift({
                                media: "(min-width: 768px)",
                                srcset: card?.images?.default || ""
                            })
                        }

                        return (
                            <div className='blogs__card' key={`card-${index}`}>
                                <CardEvent
                                    {...card}
                                    src={images}
                                    to={fixedUrlTo(`/blogs/${card.id}`)}
                                    mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)}
                                    mouseLeave={(card: HTMLElement) => clearTransform(card)}
                                    onChange={(e:any) => onChange(e)}
                                />
                            </div>
                        )
                    })
                )}
            </section>
            {isLoading && <LazyLoadImage className='blogs__loader' src='/assets/images/loading.png' alt='ufo-light'/>}
            <LazyLoadImage afterLoad={() => setAnimate('.blogs__nlo')} className='blogs__nlo blogs__animate' src='/assets/images/nlo.png'/>
            <LazyLoadImage afterLoad={() => setAnimate('.blogs__rocket')} className='blogs__rocket blogs__animate' src='/assets/images/raketa.png'/>
            <Sprite />
        </section>
    );
}

export default Blogs;
