import React, { ReactElement } from 'react';
import { useLocation } from "react-router-dom";
import { SwitchTransition, Transition } from "react-transition-group";
import { gsap } from "gsap";

type ComponentProps = {
  children: ReactElement
}

const TransitionComponent = ({
  children,
}: ComponentProps) => {
  const location = useLocation();

  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={1000}
        onEnter={(node: HTMLElement) => {
          gsap.set(node, { autoAlpha: 0, xPercent: 100 });
          gsap
            .timeline({ paused: true })
            .to(node, { autoAlpha: 1, xPercent: 0, duration: 0.5, onComplete: () => {if (node) node.style.transform = "none"}})
            .play();
        }}
        onExit={(node) => {
          gsap
            .timeline({ paused: true })
            .to(node, { xPercent: -100, autoAlpha: 0, duration: 0.5 })
            .play();
        }}
      >
        {children}
      </Transition>
    </SwitchTransition>
  )
}

export default TransitionComponent;
