import './styles.scss';
import React from 'react';
import Tippy from "@tippyjs/react";

export type TooltipType = {
  children?: JSX.Element,
  text: string,
  disabled: boolean,
}

const Tooltip = ({
  children,
  text,
  disabled,
}: TooltipType) => {
  if (!children) return null;

  return (
    <Tippy
      className="tooltip"
      placement="bottom"
      content={text}
      interactive={true}
      duration={[150, 0]}
      offset={[10, 10]}
      arrow={`<div class="tooltip__arrow"></div>`}
      disabled={disabled}
    >
      {React.cloneElement(
        children,
        children.props,
      )}
    </Tippy>
  )
}

Tooltip.defaultProps = {
  text: "",
  disabled: false,
}

export default Tooltip;
