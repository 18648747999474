import './style.scss';
import React, {useContext, useState, useEffect} from 'react';
import Layout from '../../layout';
import Sprite from "../../assets/scripts/Sprite";
import Socials from '../../components/molecules/socials';
import AIcon from '../../components/atoms/icon';
import { request } from "../../api/api";
import { SEOTypes } from "../../types";
import useChangeSEO from "../../hooks/useChangeSEO";
import { NotFoundContext } from '../../context/NotFoundContext';
import ymTrigers from "../../configs/ymTrigers";

const urlApiContacts = process.env.REACT_APP_STAGE === "integration" ? "getPageContacts/" : '/ajax/contacts.json';

const dataJSON = require('../../data/contacts.json');
const currentLang = process.env.REACT_APP_LANG ?? "ru";
const staticData = dataJSON[currentLang];

type listItem = {
    link: {
        target?: string,
        text?: string,
        icon?: {
            name: string
        }
    }
}

const Contacts = () => {
    const [ajaxData, setAjaxData] = useState<any>({});
    const [context, setContext] = useContext(NotFoundContext);

    useEffect(() => {
        request({
            method: "GET",
            url: urlApiContacts,
            params: {
                lang: process.env.REACT_APP_LANG
            }
        }).then(response => {
            setAjaxData(response.data);
        }).catch(() => {
            setContext(true);
        });
    }, []);

    const seoData: Partial<SEOTypes> = {
        description: `${ajaxData?.tel || ""} ${ajaxData?.email || ""}`,
        title: staticData.title,
        ...ajaxData.seo,
    }

    useChangeSEO({data: seoData});

    return (
            <div className='contacts'>
                <section className='contacts__section'>
                    {staticData?.title && typeof staticData.title === 'string' && <h1 className='contacts__heading caption-7'>{staticData.title}</h1>}
                    <div className='contacts__blocks'>
                        {staticData?.info && (
                            <div className='contacts__info'>
                                {staticData.info?.title && typeof staticData.info.title === 'string' &&(
                                    <div className='contacts__title caption-4'>
                                        {staticData.info.title}
                                    </div>
                                )}
                                {(ajaxData && (ajaxData?.tel || ajaxData?.email)) && (
                                    <ul className='contacts__list contacts__list--info'>
                                        {ajaxData?.tel && typeof ajaxData.tel === 'string' &&(
                                            <li className='text-6'>
                                                <a className='contacts__link' href={`tel:${ajaxData.tel}`} onClick={() => ymTrigers.phoneNumberClick()}>
                                                    <span>{ajaxData.tel}</span>
                                                </a>
                                            </li>
                                        )}
                                        {ajaxData?.email && typeof ajaxData.email === 'string' && (
                                            <li className='text-6'>
                                                <a className='contacts__link' href={`mailto:${ajaxData.email}`}>
                                                    <span>{ajaxData.email}</span>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                )}
                                {ajaxData && ajaxData?.socials && (
                                    <Socials className='socials--type-contacts' items={ajaxData.socials} />
                                )}
                            </div>
                        )}
                        {staticData?.addresses && (
                            <div className='contacts__addresses'>
                                {staticData.addresses?.title && typeof staticData.addresses?.title === 'string' &&(
                                    <div className='contacts__title caption-4'>
                                        {staticData.addresses.title}
                                    </div>
                                )}
                                {ajaxData && Array.isArray(ajaxData.addresses) && Array.isArray(staticData.addresses?.list)&& (
                                    <ul className='contacts__list contacts__list--addresses'>
                                        {staticData.addresses.list.map((item: listItem, index: number) => (
                                            <li className='text-6' key={`addresses-${index}`}>
                                                {ajaxData.addresses[index]?.text && (
                                                    <div className='contacts__text'>{ajaxData.addresses[index].text}</div>
                                                )}
                                                {ajaxData && ajaxData.addresses[index]?.href && item?.link && (
                                                    <a className='contacts__link contacts__link--addresses text-2' href={ajaxData.addresses[index].href} target={item.link.target}>
                                                        {item.link?.text && <span>{item.link.text}</span>}
                                                        {item.link?.icon && <div className='contacts__link-icon contacts__link-icon--addresses'><AIcon {...item.link.icon}/></div>}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                        {staticData?.vacancies && (
                            <div className='contacts__vacancies'>
                                {staticData.vacancies?.title && typeof staticData.vacancies?.title === 'string' &&(
                                    <div className='contacts__title caption-4'>
                                        {staticData.vacancies.title}
                                    </div>
                                )}
                                {ajaxData && Array.isArray(ajaxData.vacancies) && Array.isArray(staticData.vacancies.list) && (
                                    <ul className='contacts__list'>
                                        {staticData.vacancies?.list.map((item: listItem, index: number) => (
                                            <li className='text-6' key={`vacancies-${index}`}>
                                                {(ajaxData.vacancies[index]?.text || ajaxData.vacancies[index]?.href) && item?.link && (
                                                    <a className='contacts__link' href={ajaxData.vacancies[index].href} target={item.link.target}>
                                                        {ajaxData.vacancies[index].text && <span>{ajaxData.vacancies[index].text}</span>}
                                                        {item.link?.icon && <div className='contacts__link-icon'><AIcon {...item.link.icon}/></div>}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                        {staticData?.bankDetails && (
                            <div className='contacts__bank-details'>
                                {staticData.bankDetails?.title && typeof staticData.bankDetails?.title === 'string' &&(
                                    <div className='contacts__title contacts__title--bank-details caption-4'>
                                        {staticData.bankDetails.title}
                                    </div>
                                )}
                                <ul className='contacts__list contacts__list--bank-details'>
                                    {ajaxData && ajaxData?.bankDetails?.entity && (
                                        <li className='text-6'>
                                            <span className='contacts__value'>{typeof ajaxData.bankDetails.entity === 'string' ? ajaxData.bankDetails.entity : ''}</span>
                                        </li>
                                    )}
                                    {ajaxData && ajaxData?.bankDetails?.inn && (
                                        <li className='text-6'>
                                            <span className='contacts__value'>{typeof ajaxData.bankDetails.inn === 'string' ? ajaxData.bankDetails.inn : '' }</span>
                                        </li>
                                    )}
                                    {ajaxData && ajaxData?.bankDetails?.kpp && (
                                        <li className='text-6'>
                                            <span className='contacts__value'>{typeof ajaxData.bankDetails.kpp === 'string' ? ajaxData.bankDetails.kpp : '' }</span>
                                        </li>
                                    )}
                                    {ajaxData && ajaxData?.bankDetails?.ogrn && (
                                        <li className='text-6'>
                                            <span className='contacts__value'>{typeof ajaxData.bankDetails.ogrn === 'string' ? ajaxData.bankDetails.ogrn : '' }</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </section>
                <Sprite />
            </div>
    );
}

export default Contacts;
