import React, {useState, Suspense, useEffect, ReactElement, useCallback} from 'react';
import {useSearchParams } from 'react-router-dom';
import './style.scss';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import TagManager from 'react-gtm-module';
import Header from '../components/organisms/header';
import Footer from '../components/organisms/footer';
import Menu from '../components/organisms/menu';
import useNotification from "../hooks/useNotification";
import TransitionComponent from "../assets/TransitionComponent";

const Popup = React.lazy(() => import('../components/organisms/popup'));
const Feedback = React.lazy(() => import("../components/organisms/feedback"));

const POPUP_FEEDBACK_ID = "feedback";
const dataJSON = require("../data/base.json");
const currentLang = process.env.REACT_APP_LANG as keyof typeof dataJSON;
const data = dataJSON[currentLang];

export type LayoutProps = {
    children: React.ReactNode,
    hideFooter?: boolean
}

const tagManagerArgs = {
    gtmId: 'GTM-KMPNGMS'
}

const POPUP_DISPLAY_TIME = 10000;

TagManager.initialize(tagManagerArgs)

const Layout = (props: LayoutProps) => {
    // @ts-ignore
    const header: HeaderState = window.INIT_DATA;
    const [isOpen, setOpen] = useState(false);
    const [popupForm, setPopupForm] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (popupForm) return;
        const popupId = searchParams.get("popup");
        if (!popupId) return;
        if (popupId === POPUP_FEEDBACK_ID) {
            setPopupForm(true);
        }
    }, [searchParams]);

    const onClosePopup = () => {
        setPopupForm(false);
        searchParams.delete("popup");
        setSearchParams(searchParams);
    }

    const { renderMessage, setNotificationData } = useNotification({ timeView: POPUP_DISPLAY_TIME });

    return (
        <>
            {renderMessage}
            <Header
            isOpen={isOpen}
            toggleMenu={() => {
                setOpen(!isOpen);
            }}
            togglePopup={(e) =>{
                !popupForm ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
                setPopupForm(!popupForm);
                searchParams.set("popup", POPUP_FEEDBACK_ID);
                setSearchParams(searchParams);
            }}/>
            {popupForm && <Suspense fallback={<div></div>}>
                <Popup isShow={!popupForm} className="header__popup" type='form' onClose={onClosePopup}>
                    <Feedback
                        {...data.popup_form}
                        form={data.popup_form.form}
                        title={header?.popup_form.title || ""}
                        subtitle={header?.popup_form.subtitle || ""}
                        onClose={onClosePopup}
                        onNotification={({ notification }) => setNotificationData(notification)}
                    />
                </Popup>
            </Suspense>}
            {
                isOpen
                &&
                <Menu
                    isOpen={isOpen}
                    clickNav={() => {
                        setOpen(!isOpen);
                    }}
                />
            }
              <main className="layout__main">{props.children ? props.children : ''}</main>
            {!props.hideFooter && <Footer />}
        </>
    )
}

Layout.defaultProsp = {
    hideFooter: false,
}

export default Layout;
