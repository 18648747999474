import './style.scss';
import React from 'react';
import ErrorBlock from '../../components/organisms/error-block';

const dataJSON = require('../../data/500.json');
const currentLang = process.env.REACT_APP_LANG ?? "ru";
const staticData = dataJSON[currentLang];

const Page500 = () => {
    return (
        <ErrorBlock {...staticData}/>
    );
}

export default Page500;
