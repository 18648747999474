import './styles.scss';
import React, { useContext, Suspense, useEffect, useState, useLayoutEffect } from 'react';
import LazyLoad from "react-lazyload";
import { useParams } from 'react-router-dom';
import { elUpViewDownHide } from "../../assets/scripts/animations";
import { SEOTypes } from "../../types";
import { request } from "../../api/api";
import useChangeSEO from "../../hooks/useChangeSEO";
import useNotification from "../../hooks/useNotification";
import { NotFoundContext } from '../../context/NotFoundContext';
import Layout from "../../layout";

const RenderBlockListWithIcon = React.lazy(() => import("./RenderBlockListWithIcon"));
const RenderBlockListThreeX = React.lazy(() => import("./RenderBlockListThreeX"));
const RenderBlockWithVideo = React.lazy(() => import("./RenderBlockWithVideo"));
const RenderBlockWithButton = React.lazy(() => import("./RenderBlockWithButton"));
const RenderBlockMarkedList = React.lazy(() => import("./RenderBlockMarkedList"));
const RenderBlockListTwoX = React.lazy(() => import("./RenderBlockListTwoX"));
const RenderBlockProjects = React.lazy(() => import("./RenderBlockProjects"));
const RenderBlockReviews = React.lazy(() => import("./RenderBlockReviews"));
const RenderBlockButtonTextPhoto = React.lazy(() => import("./RenderBlockButtonTextPhoto"));
const RenderBlockText = React.lazy(() => import("./RenderBlockText"));
const RenderBlockEvents = React.lazy(() => import("./RenderBlockEvents"));
const RenderBlockWithLogo = React.lazy(() => import("./RenderBlockWithLogo"));
const RenderBlockListNX = React.lazy(() => import("./RenderListNX"));
const Sprite = React.lazy(() => import("../../assets/scripts/Sprite"));
const Banner = React.lazy(() => import("../../components/organisms/banner"));
const Feedback = React.lazy(() => import("../../components/organisms/feedback"));

const dataJSON = require('../../data/detailService.json');
const currentLang = process.env.REACT_APP_LANG ?? "ru";
const staticData = dataJSON[currentLang];
const urlByStages = process.env.REACT_APP_STAGE === "integration" ? "getPageService/" : "/ajax/service-detail.json";

const DetailService = () => {
  const [serviceData, setServiceData] = useState<any>({});
  const { service_id } = useParams();
  const [context, setContext] = useContext(NotFoundContext);

  useEffect(() => {
    request({
      method: "GET",
      url: urlByStages,
      params: {
        lang: process.env.REACT_APP_LANG,
        code: service_id
      }
    }).then(({ data }) => {
      setServiceData(data);
    }).catch(() => {
      setContext(true);
    });
  }, [service_id]);

  const elementsRender: {[x: string]: React.LazyExoticComponent<({data, staticData}: {data: any, staticData: any}) => JSX.Element>} = {
    listWithIcon: RenderBlockListWithIcon,
    list3x: RenderBlockListThreeX,
    blockWithVideo: RenderBlockWithVideo,
    blockWithButton: RenderBlockWithButton,
    markedList: RenderBlockMarkedList,
    list2x: RenderBlockListTwoX,
    projects: RenderBlockProjects,
    reviews: RenderBlockReviews,
    blockButtonTextPhoto: RenderBlockButtonTextPhoto,
    blockText: RenderBlockText,
    blockEvent: RenderBlockEvents,
    blockWithLogo: RenderBlockWithLogo,
    listNx: RenderBlockListNX,
  }

  const [ref, setRef] = useState<HTMLElement | null>(null);
  useLayoutEffect(() => {
    if (!ref) return;
    const feedback = ref.querySelector(".feedback");

    const contentAnimate = elUpViewDownHide(ref, {
      start: "top 97%",
      end: "+=300 100%",
      toggleActions: "play play none none",
    }).fromTo(feedback, {
      y: 100,
      opacity: 0,
    }, {
      duration: .3,
      y: 0,
      opacity: 1,
    });

    return () => {
      contentAnimate.kill();
    }
  }, [ref]);

  const seoData: Partial<SEOTypes> = {
    title: serviceData.banner?.title || "",
    description: serviceData.banner?.subtitle || "",
    image: serviceData.banner?.image[0].srcset || "",
    ...serviceData.seo,
  }

  const { renderMessage, setNotificationData } = useNotification({ timeView: 10000 });

  useChangeSEO({data: seoData});

  return (
    <div className="detail-service">
        <Suspense fallback={<div style={{ width: "100%", height: `100vh`}}></div>}>
          <Sprite />
          {serviceData.banner && (
            <Banner className="detail-service__banner" {...serviceData.banner} />
          )}

          {Array.isArray(serviceData.blocks) && serviceData.blocks?.map((data: any, n: number, arr: any) => {
            const viewLine_1 = data.id === 'listWithIcon' && arr.find((item: any) => item.id === 'list3x');
            const viewLine_2 = data.id === 'list2x' && arr.find((item: any) => item.id === 'projects');

            if (data.id && elementsRender[data.id]) {
              const Block = elementsRender[data.id];

              return (
                <React.Fragment key={'detail-service-block-' + n}>
                  <LazyLoad height="100%">
                    <Block data={data} staticData={staticData} />
                    {(viewLine_1 || viewLine_2) && (
                      <section className="detail-service__section-line container"></section>
                    )}
                  </LazyLoad>
                </React.Fragment>
              )
            } else {
              return null;
            }
          })}

          {staticData.feedback && (
            <LazyLoad height="100%">
              <section className='container detail-service__feedback'
              ref={setRef}>
                <Feedback
                  {...staticData.feedback}
                  title={serviceData.feedback?.title}
                  subtitle={serviceData.feedback?.subtitle}
                  onNotification={({notification}) => setNotificationData(notification)}
                  className="feedback--on-page"
                />
              </section>
            </LazyLoad>
          )}
        </Suspense>
      {renderMessage}
    </div>
  )
}

export default DetailService;
