import "./style.scss";
import React, { Suspense, useContext, useEffect, useMemo, useState } from 'react';
import Layout from '../../layout';
import { gsap, ScrollTrigger } from 'gsap/all';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyLoad from 'react-lazyload';
import { CardServiceType } from '../../components/molecules/card-service';
import { clearTransform, transformCard } from '../../assets/scripts/animations';
import Sprite from '../../assets/scripts/Sprite';
import Parser from 'html-react-parser';
import Release from '../../components/organisms/release';
import Team from '../../components/organisms/team';
import CardReview, { CardReviewType } from '../../components/molecules/card-review';
import GridSection from '../../components/organisms/grid-section';
import CardLogo, { CardLogoType } from '../../components/molecules/card-logo';
import { ReactSVG } from 'react-svg';
import Popup from '../../components/organisms/popup';
import ALink from '../../components/atoms/link';
import AButton from '../../components/atoms/button';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { useSearchParams } from 'react-router-dom';
import { SEOTypes } from "../../types";
import { request } from "../../api/api";
import useChangeSEO from "../../hooks/useChangeSEO";
import useNotification from "../../hooks/useNotification";
import { NotFoundContext } from '../../context/NotFoundContext';
import ymTrigers from "../../configs/ymTrigers";
import Footer from "../../components/organisms/footer";

const CardService = React.lazy(() => import('../../components/molecules/card-service'));
const Slider = React.lazy(() => import('../../components/organisms/slider'));
const About = React.lazy(() => import('../../components/organisms/about'));

const dataJSON = require("../../data/agency.json");
const currentLang = process.env.REACT_APP_LANG as keyof typeof dataJSON;
const data = dataJSON[currentLang];
const Feedback = React.lazy(() => import("../../components/organisms/feedback"));
gsap.registerPlugin(ScrollTrigger);
const POPUP_FEEDBACK_ID = "feedback_in_agency";
const urlByStages = process.env.REACT_APP_STAGE === "integration" ? "getPageAgency/" : '/ajax/agency.json';

const createScrollTriggerParallax = (triggerSelector: string) => {
    ScrollTrigger.matchMedia({
        "(min-width: 768px)": () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: triggerSelector,
                    start: 'top 80%',
                    end: 'bottom 20%',
                    scrub: 1,
                }
            });

            tl.fromTo(triggerSelector, { y: '75%' }, {
                y: '-35%',
            });
        }
    });
    ScrollTrigger.refresh();
}

const createScrollTriggerParallaxSmall = (triggerSelector: string, y1: string, y2: string) => {
    ScrollTrigger.matchMedia({
        "(min-width: 768px)": () => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: triggerSelector,
                    start: 'top 40%',
                    end: 'bottom 0',
                    scrub: 1,
                }
            });

            tl.fromTo(triggerSelector, {
                y: y1,
            }, {
                y: y2,
            });
        }
    });
}

const Agency = () => {
    const baseData = (window as any)?.INIT_DATA;
    const imageTl = useMemo(() => gsap.timeline({
        repeat: -1,
        repeatDelay: 0.2,
        yoyo: true,
    }), []);


    const imageAnimation = () => {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                imageTl.fromTo('.agency__advantage-animation-icon', {
                    y: 0,
                }, {
                    y: -50,
                    duration: 4,
                    ease: 'none'
                });
            }
        })
    }

    const planetTl = useMemo(() => gsap.timeline({
        repeat: -1,
        repeatDelay: 0.5,
        defaults: {
            scrollTrigger: {
                trigger: '.agency__planet',
                end: "75% center",
                toggleActions: "play pause resume play"
            }
        }
    }), []);

    const planetAnimate = () => {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                if (planetTl.labels.loadded !== undefined) return;
                planetTl.addLabel('loadded').fromTo('.agency__planet', {
                    rotate: 0,
                    x: 0
                }, {
                    rotate: 360,
                    x: 0,
                    duration: 300,
                });

                return () => {
                    planetTl.progress(0).pause();
                }
            },
            "(max-width: 767px)": () => {
                planetTl.progress(0).pause();
                return () => {
                    planetTl.play();
                }
            }
        });
    }

    const [context, setContext] = useContext(NotFoundContext);
    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const [ajaxData, setAjaxData] = useState<any>({});
    const [popupGift, setPopupGift] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const [popupForm, setPopupForm] = useState(false);
    const [popupComment, setPopupComment] = useState({
        isView: false,
        data: {
            company: {
                src: "",
                name: ""
            },
            client: {
                src: "",
                name: "",
                position: ""
            },
            text: ""
        },
    });

    useEffect(() => {
        if (popupForm) return;
        const popupId = searchParams.get("popup");
        if (!popupId) return;
        if (popupId === POPUP_FEEDBACK_ID) {
            setPopupForm(true);
        }
    }, [searchParams]);

    const onClosePopup = () => {
        setPopupForm(false);
        searchParams.delete("popup");
        setSearchParams(searchParams);
    }
    const togglePopupForm = (e: any) => {
        setPopupGift(false)
        !popupForm ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
        setPopupForm(!popupForm);
        setSearchParams({
            popup: POPUP_FEEDBACK_ID,
        });
    }

    useEffect(() => {
        request({
            method: "GET",
            url: urlByStages,
            params: {
                lang: process.env.REACT_APP_LANG
            },
        }).then(res => {
            setAjaxData(res.data);
        }).catch(() => {
            setContext(true);
        });
    }, [])

    ScrollTrigger.addEventListener("refreshInit", () => {
        setMobile(window.innerWidth < 768)
    });
    
    const metrika = () => {
        ymTrigers.phoneNumberClick();
    }

    const seoData: Partial<SEOTypes> = {
        description: ajaxData.subtitle,
        title: ajaxData.title,
        ...ajaxData.seo,
    }

    const { setNotificationData, renderMessage } = useNotification({ timeView: 10000 });

    useChangeSEO({data: seoData});

    return (
        <>
            <Suspense fallback={<div></div>}>
                {data.popup_gift && popupGift && <Popup isShow={popupGift} type='gift' onClose={() => setPopupGift(false)}>
                    <div className='popup__heading'>
                        {data.popup_gift.title && typeof data.popup_gift.title === 'string' && <h3 className='caption-3'>{Parser(data.popup_gift.title)}</h3>}
                        {data.popup_gift.text && typeof data.popup_gift.text ==='string' && <span className='text-2'>{Parser(data.popup_gift.text)}</span>}
                    </div>
                    <div className='popup__controls'>
                        {Array.isArray(data.popup_gift.controls) && data.popup_gift.controls.map((item: any) => (
                            item.subtext ?
                                <AButton key={item.text} text={item.text} subtext={item.subtext} onClick={togglePopupForm} /> :
                                process.env.REACT_APP_LANG === 'ru'
                                    && baseData?.tel
                                    && <ALink key={item.text} type='no-bg' text={item.text} subtext={baseData?.tel} href={`tel:${baseData?.tel}`} onClick={metrika} />
                        ))}
                    </div>
                </Popup>}
                {popupForm &&
                    <Popup isShow={!popupForm} className="header__popup" type='form' onClose={onClosePopup}>
                        <Feedback
                            {...data.popup_form}
                            form={data.popup_form.form}
                            title={ajaxData?.popup_form?.title || ""}
                            subtitle={ajaxData?.popup_form?.subtitle || ""}
                            onClose={onClosePopup}
                            onNotification={({ notification }) => setNotificationData(notification)}
                            data={{ gift: true }}
                        />
                    </Popup>}
            </Suspense>
                <div className="agency ">
                    {renderMessage}
                    <div className="agency__entry">
                        <span className="agency__entry-gradient"></span>
                        <span className="agency__entry-gradient agency__entry-gradient--blue"></span>
                        <div className="agency__planet-wrapper">
                            <picture>
                                <source srcSet="/assets/images/planet.webp" media="(min-width: 786px)" type="image/webp" />
                                <source srcSet="/assets/images/agency-planet-mobile.webp" type="image/webp" />
                                <source srcSet="/assets/images/planet.png" media="(min-width: 786px)" />
                                <source srcSet="/assets/images/agency-planet-mobile.png" />
                                <img src="" alt="planet" className='agency__planet' onLoad={planetAnimate} width={360} height={1200} />
                            </picture>
                        </div>
                        <div className="agency__entry-planet"></div>
                        <div className="agency__entry-planet agency__entry-planet--small"></div>
                    </div>
                    <section className='agency__heading container'>
                        {ajaxData.title && typeof ajaxData.title === 'string' && <h1 className='agency__title caption-7'>{Parser(ajaxData.title)}</h1>}
                        {ajaxData.subtitle && typeof ajaxData.subtitle === 'string' && <div className='agency__subtitle text-6'>{Parser(ajaxData.subtitle)}</div>}
                    </section>
                    <LazyLoad offset={500} height={350} className='agency__lazy-slider'>
                        {ajaxData && ajaxData.services && <Slider
                            sliderClass='container__inner agency__slider'
                            slidesPerView={4}
                            heading={{
                                titleType: "caption-3",
                                title: ajaxData.services.title,
                                className: 'agency__description'
                            }}
                            items={[...ajaxData.services.items, ...data.services.items]}
                            render={(i: CardServiceType) => (
                                <CardService
                                    {...i}
                                    description=""
                                    mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)}
                                    mouseLeave={(card: HTMLElement) => clearTransform(card)}
                                />
                            )}
                        />}
                    </LazyLoad>
                    <LazyLoad offset={500} height={350}>
                        {ajaxData && ajaxData.about && <About {...ajaxData.about} />}
                    </LazyLoad>
                    <LazyLoad height={350}>
                        <Suspense fallback={<div style={{height: 350}}></div>}>
                            <div className="agency__advantage">
                                <LazyLoad height={350}>
                                    {ajaxData && ajaxData.projects && (
                                        <div className="agency__advantage-wrapper">
                                            <Release
                                                heading={{
                                                    titleType: "caption-3",
                                                    title: ajaxData.projects.title
                                                }}
                                                items={ajaxData.projects.items}
                                                className='agency__advantage-bcg'
                                                grid='card-grid-2'
                                            />
                                        </div>
                                    )}
                                </LazyLoad>
                                <div className='agency__advantage-animation container'>
                                    <LazyLoadImage
                                        className='agency__advantage-animation-icon'
                                        src={isMobile ? '' : '/assets/images/icon-agency-animation.png'}
                                        alt='image'
                                        afterLoad={() => imageAnimation()}
                                    />
                                </div>
                                <LazyLoad height={350}>
                                    {ajaxData && ajaxData.release && (
                                        <div className="agency__advantage-wrapper">
                                            <Release
                                                heading={{
                                                    titleType: "caption-4",
                                                    title: ajaxData.release.title,
                                                }}
                                                items={ajaxData.release.items}
                                                link={{
                                                    ...data.release.link,
                                                    text: ajaxData.release?.link?.title,
                                                    to: ajaxData.release?.link?.url,
                                                    className: 'link release__link agency__advantage-link'
                                                }}
                                                className='agency__advantage-bcg'
                                            />
                                        </div>
                                    )}
                                </LazyLoad>
                            </div>
                        </Suspense>
                    </LazyLoad>
                    <LazyLoad offset={500} height={350} className="agency__team-wrapper">
                        {ajaxData && ajaxData.team && <Team {...ajaxData.team} />}
                    </LazyLoad>
                    <LazyLoad offset={500} height={350}>
                        <div className="agency__parallax">
                            <LazyLoadImage
                                className='agency__space'
                                src='/assets/images/agency-space.webp'
                            />
                            <LazyLoadImage
                                afterLoad={() => createScrollTriggerParallax('.agency__sphere--medium')}
                                className='agency__sphere agency__sphere--medium'
                                src='/assets/images/agency-planet-big.png'
                            />
                            <LazyLoadImage
                                afterLoad={() => createScrollTriggerParallax('.agency__sphere.agency__sphere--big')}
                                className='agency__sphere agency__sphere--big'
                                src='/assets/images/agency-planet-big.png'
                            />
                            <LazyLoadImage
                                afterLoad={() => createScrollTriggerParallax('.agency__sphere.agency__sphere--small')}
                                className='agency__sphere agency__sphere--small'
                                src='/assets/images/agency-planet-big.png'
                            />
                        </div>
                    </LazyLoad>
                    <LazyLoad offset={500} height={420}>
                        <GridSection
                            dataList={ajaxData.awards}
                            col={4}
                            titleType="caption-9"
                            className="detail-service__list-n-x list-section--items-between-v2 agency__awards"
                            typeView="sliderInMobile"
                            swiperOptions={{
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                                spaceBetween: 8,
                            }}
                            renderCard={(item: CardLogoType) => {
                                return (
                                    <CardLogo
                                        className='agency__card-logo'
                                        image={item.image}
                                        hintDisabled
                                        description={item.description}
                                    />
                                )
                            }}
                        />
                    </LazyLoad>
                    <LazyLoad offset={500} height={350}>
                        <div className="agency__thanks container">
                            <GridSection
                                dataList={ajaxData.thanks}
                                col={4}
                                titleType="caption-10"
                                className="detail-service__list-n-x list-section--items-between-v2"
                                typeView="sliderInMobile"
                                swiperOptions={{
                                    slidesPerView: 2,
                                    slidesPerGroup: 2,
                                    spaceBetween: 8,
                                }}
                                renderCard={(item: CardLogoType) => {
                                    return (
                                        <CardLogo
                                            className='card-logo agency__thanks-card-logo'
                                            image={item.image}
                                            hintDisabled
                                        />
                                    )
                                }}
                            />
                            <LazyLoadImage
                                afterLoad={() => createScrollTriggerParallaxSmall('.agency__thanks-planet', '380%', '0')}
                                className='agency__thanks-planet'
                                src='/assets/images/planet-right.png'
                            />
                            <LazyLoadImage
                                className='agency__galactic-right'
                                src='/assets/images/galactic-right.webp'
                            />
                        </div>
                    </LazyLoad>
                    <LazyLoad offset={500} height={350}>
                        {ajaxData && ajaxData.reviews &&
                            <>
                                <Slider
                                    heading={{ title: ajaxData.reviews.title, titleType: "caption-10" }}
                                    items={ajaxData.reviews.items}
                                    custom={true}
                                    render={(i: CardReviewType) => (
                                        <CardReview
                                            loadMore={data.reviews.text} {...i}
                                            togglePopup={(e, data) => {
                                                !popupComment
                                                    ? disableBodyScroll(e.currentTarget as HTMLElement)
                                                    : enableBodyScroll(e.currentTarget as HTMLElement); setPopupComment({ isView: true, data });
                                            }}
                                        />
                                    )}
                                />
                                {popupComment.isView &&
                                    <Suspense fallback={<div></div>}>
                                        <Popup className="popup__popup" type='comment' isShow={popupComment.isView}
                                            onClose={() => setPopupComment({ ...popupComment, isView: false })}>
                                            <div>
                                                <div className='popup__heading-review'>
                                                    <div>
                                                        {popupComment.data.company.src && <LazyLoadImage className='popup__logo' src={popupComment.data.company.src} alt={`logo of ${popupComment.data.company.name}`} />}
                                                        {popupComment.data.client.src && <LazyLoadImage className='popup__logo' src={popupComment.data.client.src} alt={`avatar of ${popupComment.data.client.name}`} />}
                                                    </div>
                                                    <div className='popup__wrapper-review'>
                                                        <span className='text-4 popup__title'>{popupComment.data.client.name}</span>
                                                        <span className='text-2 popup__description' >{popupComment.data.client.position}, {popupComment.data.company.name}</span>
                                                    </div>
                                                </div>
                                                <div className='text-6 popup__text'>
                                                    {Parser(popupComment.data.text)}
                                                </div>
                                                <button className='popup__button popup__button--review-close' data-close="true" aria-label="close button">
                                                    {data.reviews.button}
                                                </button>
                                            </div>
                                        </Popup>
                                    </Suspense>
                                }
                            </>
                        }
                    </LazyLoad>
                    <LazyLoad offset={500} height={350}>
                        <div className="agency__action container">
                            {ajaxData.giftTitle && typeof ajaxData.giftTitle === 'string' && <div className='agency__action-title caption-4'>{Parser(ajaxData.giftTitle)}</div>}
                            <div className='agency__action-wrapper'>
                                <img className='agency__astronaut' src="/assets/images/austronaut-agency.png" alt="austronaut-agency" />
                                <ReactSVG className='agency__cable' src={`/assets/svg/cable.svg`} />
                                <ReactSVG className='agency__hole' src={`/assets/svg/hole.svg`} />
                                <ReactSVG onClick={() => setPopupGift(true)} className='agency__intersect' src={`/assets/svg/black-hole-section.svg`} />
                            </div>
                        </div>
                    </LazyLoad>
                </div>
                <Footer />
                <Sprite />
        </>
    )
}

export default Agency;
