import './style.scss';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cn from "classnames";

export type ImageType = {
  className?: string,
  alt?: string,
  sources?: {
    srcset: string,
    media: string,
    type?: string,
  }[],
  src: string,
  afterLoad?: () => any,
  visibleByDefault?: boolean,
}

const Image = (props: ImageType) => {
  const onLoadedImage = (e: any) => {
    e.target.width = e.target.parentElement.offsetWidth;
    e.target.height = e.target.parentElement.offsetHeight;

    e.target.parentNode.classList.remove("image--show-placeholder");
  }

  return (
    <picture className={cn('image', props.className, "image--show-placeholder")}>
      {
        Array.isArray(props.sources)
        &&
        props.sources.map((source, index) => (
          <source srcSet={source.srcset} media={source.media} key={`source-${index}`} type={source.type ?? undefined} />
        ))
      }
      <div className="image__placeholder"></div>

      <LazyLoadImage
        afterLoad={props.afterLoad}
        alt={props.alt}
        src={props.src}
        // @ts-ignore
        onLoad={onLoadedImage}
        visibleByDefault={props.visibleByDefault}
      />
    </picture>
  );
}

export default Image;

Image.defaultProps = {
  src: "",
  visibleByDefault: false,
  alt: 'image',
}
