import './style.scss';
import React from 'react';
import debounce from '../../../assets/scripts/debounce';
import Parser from 'html-react-parser';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Link, useLocation } from "react-router-dom";
import Image, { ImageType } from "../../atoms/image";
import { GridImagesTypes } from "../../../types";
import fixedUrlTo from "../../../assets/scripts/fixedUrlTo";

export type VideoType = {
  src: string,
  poster?: string,
}

export type CardReleaseType = {
  className?: string,
  id?: string,
  src?: string | ImageType["sources"],
  images?: GridImagesTypes
  alt?: string,
  href?: string,
  frame?: string,
  label?: string,
  text?: string,
  size?: string,
  bg_color?: string,
  font_color?: string,
  poster?: string,
  mouseMove?: (e: React.MouseEvent, card: HTMLElement) => void,
  mouseLeave?: (card: HTMLElement) => void
}

const CardRelease = (props: CardReleaseType) => {
  const card = React.useRef<HTMLDivElement | null>(null);
  const urlParam = useLocation().search;

  const mouseMove = (e: React.MouseEvent) => {
    if (props.mouseMove) {
      props.mouseMove(e, card.current!)
    }
  }

  const mouseLeave = () => {
    if (props.mouseLeave) {
      props.mouseLeave(card.current!)
    }
  }

  const firstColorRGBA = props.bg_color?.replace(/[^,]+(?=\))/, '0');

  const renderOneIf = () => {
    if (props.poster !== undefined && props.poster !== '') {
      if (!props.src) return null;

      if (typeof props.src === "string") {
        return (
          <video src={props.src} poster={props.poster} controls />
        )
      } else return null;
    }
    if (props.frame) {
      return Parser(props.frame)
    }
    return (
      <>
        {Array.isArray(props.src) && (
          <Image sources={props.src} className="card-release__image" />
        )}
        {(props.label || props.text) &&
          <div
            className='card-release__wrapper'
            style={{
              background: `linear-gradient(180deg, ${firstColorRGBA} 0%, ${props.bg_color || '#fff'} 50%)`,
              color: `${props.font_color || '#010510'}`
            }}
          >
            {props.label && typeof props.label === 'string' && <span className='text-3 card-release__label'>{props.label}</span>}
            {props.text && typeof props.text === 'string' && <span className='text-1 card-release__text'>{props.text}</span>}
          </div>
        }
      </>
    )
  };

  const render = (
    <div
      ref={card}
      className={[
        'card-release',
        props.className,
        props.size
          ? `card-release--${props.size}` : '',
        props.frame || props.poster ? `card-release--video` : ''
      ].join(' ')}
      onMouseMove={(e) => debounce(mouseMove(e), 100)}
      onMouseLeave={mouseLeave}
      aria-label="card link"
    >
      <LazyLoadComponent key="card-realese-image">{renderOneIf()}</LazyLoadComponent>
    </div>
  );

  if (props.href) {
    return (
      <Link className='release__item-wrapper card-release__item-wrapper' to={fixedUrlTo(`${props.href}${urlParam}`)}>
        {render}
      </Link>
    )
  }

  return render;
};

CardRelease.propsDefault = {
  images: []
}

export default CardRelease;
