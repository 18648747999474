import React, {useContext, useCallback, useEffect, useState } from 'react';
import Parser from "html-react-parser";
import Sprite from "../../assets/scripts/Sprite";
import CardService, { CardServiceType } from "../../components/molecules/card-service";
import { clearTransform, transformCard } from '../../assets/scripts/animations';
import { SEOTypes } from "../../types";
import { request } from "../../api/api";
import debounce from "../../assets/scripts/debounce";
import useChangeSEO from "../../hooks/useChangeSEO";
import { NotFoundContext } from '../../context/NotFoundContext';

import './styles.scss';

type ServicesTypes = {
  items: Array<CardServiceType>,
  total: number,
  title?: string,
  description?: string,
  seo?: Partial<SEOTypes>,
}

const urlByStages = process.env.REACT_APP_STAGE === "integration" ? "getPageServices/" : "/ajax/services.json";

const PageServices = () => {
  const [data, setData] = useState<ServicesTypes>({items: [], total: 0});
  const isAllLoaded = data.total <= data.items.length;
  const [context, setContext] = useContext(NotFoundContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, []);

  useEffect(() => {
    request({
      method: "GET",
      url: urlByStages,
      params: {
        lang: process.env.REACT_APP_LANG
      }
    }).then(({ data }) => {
        setData(data);
    }).catch(() => {
        setContext(true);
    });
  }, []);

  const loadCommentsByEndScroll = useCallback(() => {
    const { pageYOffset, innerHeight } = window;
    const { scrollHeight } = document.body;

    const isScrolled = scrollHeight - innerHeight === pageYOffset;

    if (isScrolled && !isAllLoaded) {
      request({
        method: "GET",
        url: urlByStages,
        params: {
          lang: process.env.REACT_APP_LANG,
          offset: data.items ? data.items.length + 1 : 0,
          limit: 6,
        }
      })
        .then(({ data }) => {
          setData((prevState) => {
            const newState = prevState;
            newState.items = [...prevState.items, data.items];
            return newState;
          });
        })
    }
  }, [data.items, isAllLoaded]);

  useEffect(() => {
      window.addEventListener('scroll', debounce(loadCommentsByEndScroll));

    return () => {
      window.removeEventListener('scroll', debounce(loadCommentsByEndScroll));
    }
  }, [ loadCommentsByEndScroll ]);

  const seoData: Partial<SEOTypes> = {
    description: data.description || "",
    title: data.title || "",
    ...data.seo
  }

  useChangeSEO({data: seoData});

  return (
    <div className="services-page container">
        <section className="services-page__head">
          {data.title && typeof data.title === 'string' && <h1 className="services-page__title caption-7">{Parser(data.title)}</h1>}
          {data.description && typeof data.description === 'string' && <div className="services-page__description text-1">{Parser(data.description)}</div>}
        </section>
        {Array.isArray(data.items) && (
          <div className="services-page__services">
            {data.items.map((item: CardServiceType, n: number) => {
              return (
                <div className="services-page__service" key={'service-item-' + n}>
                  <CardService
                    {...item}
                    className="card-service--style-v2"
                    viewArrow
                    mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)}
                    mouseLeave={(card: HTMLElement) => clearTransform(card)}
                  />
                </div>
              )
            })}
          </div>
        )}
        <Sprite />
    </div>
  );
};


export default PageServices;
