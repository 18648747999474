import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import fixedUrlTo from "../../../assets/scripts/fixedUrlTo";

export type NavigationLinkType = {
    to: string,
    text?: string,
    className?: string,
    activeClass?: string,
    onClick?: () => void
}

const NavigationLink = (props: NavigationLinkType) => {
    const url = useMemo(() => new URL(props.to, window.location.origin), [props.to]);

    if (url.origin === window.location.origin) {
        return (
            <NavLink
                className={({ isActive }) => {
                    if (url.searchParams.has('id')) {
                        return `${props.className}`
                    }

                    return `${props.className} ${isActive ? `${props.activeClass}` : ""}`
                }}
                to={fixedUrlTo(props.to)}
                onClick={props.onClick}
            >
                {typeof props.text === 'string' ? props.text : ''}
            </NavLink>
        );
    }

    return (
        <a aria-label='navigation link' className={props.className} href={props.to} target='_blank' rel="noreferrer noopener">{typeof props.text === 'string' ? props.text : ''}</a>
    );
}

NavigationLink.defaultProps = {
    activeClass: ""
}

export default NavigationLink;
