import { useEffect } from 'react';
import { SEOTypes } from "../types";

type SEOProps = {
  data: Partial<SEOTypes>,
}

const changeMetaOGContent = (ogName: 'title' | 'description' | 'site_name' | 'image' | 'url', value: string) => {
  document.head.querySelector(`meta[property=og\\:${ogName}]`)?.setAttribute("content", value);
};
const changeMetaContent = (ogName: 'description' | 'keywords' | 'image' | 'url', value: string) => {
  document.head.querySelector(`meta[name=${ogName}]`)?.setAttribute("content", value);
};

const useChangeSEO = ({
  data,
}: SEOProps) => {
  useEffect(() => {
    if (!data) return;

    if (data.siteName) {
      changeMetaOGContent('site_name', data.siteName);
    }
    if (data.description) {
      changeMetaContent("description", data.description);
      changeMetaOGContent("description", data.description);
    }
    if (data.image) {
      changeMetaContent("image", data.image);
      changeMetaOGContent("image", data.image);
    }
    if (data.keywords) {
      changeMetaContent("keywords", data.keywords);
    }
    if (data.url) {
      changeMetaContent("url", data.url);
      changeMetaOGContent("url", data.url);
    }
    if (data.title) {
      changeMetaOGContent("title", data.title);
      document.title = data.title;
    }
  }, [data]);
};

useChangeSEO.defaultProps = {
  data: {},
}

export default useChangeSEO;
