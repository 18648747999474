import React, { useEffect, useState } from "react"
import Notification, { NotificationType } from "../components/molecules/notification";

export type NotificationProps = {
  timeView?: number,
}

const useNotification = ({
  timeView = 10000,
}: NotificationProps) => {
  const [notificationData, setNotificationData] = useState<NotificationType>({ text: "" });

  const onCloseNotification = () => {
    setNotificationData((prevState) => {
        return { ...prevState, text: "" }
    })
}

  useEffect(() => {
      if (notificationData.text !== "") {
          setTimeout(() => {
              onCloseNotification();
          }, timeView);
      }
  }, [timeView, notificationData.text]);

  const renderMessage = notificationData.text && (
    <Notification
      text={notificationData.text}
      type={notificationData.type}
      onClose={onCloseNotification}
    />
  )

  return {
    setNotificationData,
    notificationData,
    onCloseNotification,
    renderMessage,
  }
}

useNotification.defaultProps = {
  timeView: 10000,
}

export default useNotification;
