import './style.scss';
import React, {useEffect, useRef} from 'react';
import ALink, {ALinkType} from '../../atoms/link';
import Heading, {HeadingType} from '../../molecules/heading';
import CardRelease, {CardReleaseType} from '../../molecules/card-release';
import { transformCard, clearTransform, enterFlip } from '../../../assets/scripts/animations';
import {gsap, ScrollTrigger} from 'gsap/all';
import { ImageType } from "../../atoms/image";

gsap.registerPlugin(ScrollTrigger);

export type ReleaseType = {
    className?: string,
    heading?: HeadingType,
    items: CardReleaseType[],
    link?: ALinkType,
    refEl?: (el: HTMLElement | null) => void,
    isCardAnimated?: boolean,
    grid?: string,
    video: CardReleaseType,
}

const models = {
    "card-grid-1": ["large", "medium", "vertical", "small", "small"],
    "card-grid-2": ["vertical", "medium", "small", "small"],
    "card-grid-3": ["medium", "vertical", "small", "small"],
}

const Release = (props: ReleaseType) => {
    const card_release = useRef<HTMLElement | null>(null);

    useEffect(() => {
        if (props.isCardAnimated) {
            enterFlip('.release__item', card_release);
        }
    }, [props.isCardAnimated]);

    useEffect(() => {
        if (props.refEl) {
            props.refEl(card_release.current)
        }
    }, [props]);

    return (
        <section ref={card_release} className={`release container ${props.className || ''}`}>
            {props.heading && <Heading {...props.heading} className={`release__heading ${props.heading.control && props.heading.control.type ? '' : 'release__heading--r-shrink'}`} theme="light"/>}
            <div className={`release__list ${props.grid || 'card-grid-1'}`}>
                {Array.isArray(props.items) && props.items.map((item, i) => {
                    let images: ImageType["sources"] = [
                        {
                            media: "(min-width: 0)",
                            srcset: item.images?.small || ""
                        }
                    ]

                    const modelVariant = props.grid as keyof typeof models;
                    const modelsLength = models[modelVariant].length;
                    const key = i % modelsLength;
                    const imageKey = models[modelVariant][key];

                    images.unshift({
                        media: "(min-width: 768px)",
                        srcset: item.images?.[imageKey] || "",
                    });

                    return (
                        <div
                            key={`card-release-${i}`}
                            className={`release__item ${(item.frame || item.poster) ? `release__item--video` : ''}`}
                        >
                            <CardRelease
                                {...item}
                                {...(item.poster ? {} : {href: `/works/${item.id}` })}
                                src={images}
                                mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)}
                                mouseLeave={(card: HTMLElement) => clearTransform(card)}
                            />
                        </div>
                    )
                })}
            </div>

            {props.video && (
                <CardRelease
                    {...props.video}
                />
            )}
            
            {   props.link &&
                (props.link.href || props.link.to) &&
                props.link.text &&
                <ALink className="release__link" {...props.link}/>
            }
        </section>
    );
};

Release.defaultProps = {
    isCardAnimated: true,
    grid: 'card-grid-1',
} as ReleaseType;

export default Release;
