import './style.scss';
import React, {ChangeEvent} from 'react';
import Parser from 'html-react-parser';
import isMobile from "../../../assets/scripts/isMobile";
import cn from "classnames";

export type FilterTagType = {
    className?: string;
    name: string;
    text?: string;
    checked?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const FilterTag = (props: FilterTagType) => {
    const isNotMobile = !isMobile.any();

    return (
        <label className={cn("filter-tag", props.className)}>
            <input
                className='filter-tag__input'
                type='checkbox'
                name={props.name}
                checked={props.checked}
                onChange={props.onChange}
            />
            {props.text && typeof props.text === 'string' && <span className={cn('filter-tag__text text-2', {"filter-tag__text--hover": isNotMobile})}>{Parser(props.text)}</span>}
        </label>
    );
}

export default FilterTag;
