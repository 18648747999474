import React, {MouseEvent} from 'react';
import { useLocation } from 'react-router-dom';
import AButton, { AButtonType } from '../../atoms/button';
import { Link } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import NavigationLink from '../../atoms/navigation-link';

import './style.scss';
import ymTrigers from "../../../configs/ymTrigers";
import fixedUrlTo from "../../../assets/scripts/fixedUrlTo";

type HeaderType = {
    toggleMenu: (e: MouseEvent) => void,
    togglePopup: (e: MouseEvent) => void,
    isOpen?: boolean
}

export type NavItem = {
    to?: string,
    text: string
}

type HeaderState = {
    logo: {
        to: string,
        src: string,
        alt: string
    },
    nav: NavItem[],
    mail: string,
    lang?: {
        href?: string,
        text: string
    },
    button: AButtonType,
    popup_form?: {
        title: string,
        subtitle: string,
        back?: boolean,
        form: {
            action: string,
            method?: string,
            fields: any[],
            button: AButtonType,
        }
    }
}

const Header = (props: HeaderType) => {
    // @ts-ignore
    const header: HeaderState = window.INIT_DATA;
    const location = useLocation();

    const toggleMenu = (e: MouseEvent) => {
        props.toggleMenu(e);
    }
    const togglePopup = (e: MouseEvent) => {
        props.togglePopup(e);
        ymTrigers.formClick();
    }

    const handleClickLogo = (event: any) => {
        if (location.pathname === '/') {
            event.preventDefault();
        }
    }

    if (!header) return null;

    return (
        <header className='header'>
            <div className='header__wrapper'>
                <Link to='/' className='header__logo' onClick={handleClickLogo}>
                    <img
                        src='/assets/svg/logo.svg'
                        alt="coalla's face"
                        className='header__logo-img'
                        width={174}
                        height={22}
                    />
                </Link>
                <nav className='header__nav' key={location.pathname}>
                    {header?.nav && !!header.nav?.length && header.nav.map((item, i) => {
                        const linkTo = fixedUrlTo(item.to || "");
                        return <NavigationLink
                            className='header__item'
                            activeClass='header__item--active'
                            to={linkTo}
                            text={item?.text || ''}
                            key={`nav-${i}`}
                        />
                    })}
                </nav>
                <button className={`header__menu ${props.isOpen ? 'header__menu--active' : ''}`} onClick={(e) => toggleMenu(e)} aria-label='menu button'></button>
                <div className='header__controls'>
                    {header.mail && typeof header.mail === 'string' && <a aria-label='link mail to' className='header__link' href={`mailto:${header.mail}`}>{header.mail}</a>}
                    {header.lang && (
                        <a
                            aria-label='change language'
                            className='header__lang'
                            href={header.lang.href}
                        >
                            {typeof header.lang.text === 'string' ? header.lang.text : ''}
                        </a>
                    )}
                    {header.button && <AButton className="header__button" {...header.button} onClick={togglePopup}/>}
                </div>
            </div>
        </header>
    );
};

export default Header;
