import './style.scss';
import React from 'react';
import Parser from 'html-react-parser'
import ALink, {ALinkType} from '../../atoms/link';
import { TitleType } from "../../../types";
import fixedUrlTo from "../../../assets/scripts/fixedUrlTo";

export type HeadingType = {
    className?: string,
    title?: string,
    unit?: string,
    description?: string,
    control?: ALinkType,
    theme?: 'light',
    titleType?: TitleType,
    smallTitle?: boolean,
}

const Heading = (props: HeadingType) => {
    const renderLink = () => {
        if (props?.control?.href?.[0] === "/") {
            return <ALink {...props.control} href="" to={fixedUrlTo(props.control.href)} className='heading__link'/>
        }
        return <ALink {...props.control} rel="noreferrer noopener" target="_blank" className='heading__link'/>;
    }

    return (
        <div className={['heading', props.className ? props.className : '', props.theme ? `heading--${props.theme}` : ''].join(' ')}>
            <div className='heading__title'>
                { props.title && typeof props.title === 'string' && <h1 className={props.titleType}>{Parser(props.title)}</h1>}
                { props.unit && typeof props.unit === 'string' && <div className='text-3 heading__unit'>
                    {props.unit.split('|').map((elem, i) => (
                        <span key={i}>{Parser(elem)}</span>
                    ))}
                </div>}
            </div>
            {(props.description || props.control) && (
                <div className='heading__text'>
                    { props.description && typeof props.description === 'string' && <div className='text-6 heading__description'>{Parser(props.description)}</div>}
                    {   props.control &&
                        (props.control.to || props.control.href) &&
                        props.control.text &&
                        renderLink()
                    }
                </div>
            )}
        </div>
    );
};

Heading.defaultProps = {
    titleType: "caption-1",
}

export default Heading;
