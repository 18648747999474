import './styles.scss';
import React, { CSSProperties } from 'react';
import cn from 'classnames';
import BaseSlider from "../base-slider";
import { TitleType } from "../../../types";
import { SwiperProps } from "swiper/react";

type TypeView = "default" | "sliderInMobile";

export type DataTypes<IT extends any> = {
  title: string,
  items: Array<IT>
}

export type GridSectionTypes<IT extends any> = {
  className: string,
  titleType: TitleType,
  dataList: Partial<DataTypes<IT>>,
  renderCard: (item: any) => React.ReactElement,
  col: 2 | 3 | 4 | 11,
  typeView: TypeView,
  style: CSSProperties,
  swiperOptions: SwiperProps,
  refEl: (ref: HTMLElement | null) => void,
  renderDecor?: (props: Partial<React.HTMLProps<HTMLElement>>) => JSX.Element,
}

const GridSection = <IT extends any,>({
  className,
  titleType,
  dataList,
  renderCard,
  col,
  typeView,
  style,
  swiperOptions,
  refEl,
  renderDecor,
}: GridSectionTypes<IT>) => {
  const { title, items } = dataList;
  const isCorrectlyItems = items && Array.isArray(items) && items.length > 0;

  const renderViewItemsByTypeView = () => {
    if (!isCorrectlyItems) return null;

    switch(typeView) {
      case "sliderInMobile":
        return (
          <BaseSlider
            dataItems={items}
            renderCard={renderCard}
            isMobileView
            className="list-section__list"
            options={swiperOptions}
          />);
      default:
        return (
          <div className="list-section__list list-section__list--default">
            {Array.isArray(items) && items.map((item, n) => <React.Fragment key={n}>{renderCard(item)}</React.Fragment>)}
          </div>
        )
    }
  }

  return (
    <section
      ref={(sectionRef) => refEl(sectionRef)}
      className={cn("list-section", "container", className, {
        [`list-section--col-${col}`]: col,
      })}
      style={style}
    >
      {title && typeof title === 'string' && <h2 className={cn("list-section__title", titleType)}>{title}</h2>}
      {renderViewItemsByTypeView()}
      {renderDecor && renderDecor({ className: "list-section__decor" })}
    </section>
  )
}

GridSection.defaultProps = {
  className: "",
  titleType: "",
  dataList: {},
  renderCard: () => {},
  col: 3,
  typeView: "default",
  style: {},
  swiperOptions: {},
  refEl: () => {},
}

export default GridSection;
