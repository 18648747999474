import './style.scss';
import React, {useContext, useState, useEffect, ChangeEvent, Suspense, useRef, useLayoutEffect} from 'react';
import { useSearchParams, } from "react-router-dom";
import Parser from 'html-react-parser';
import gsap, {ScrollTrigger} from 'gsap/all';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Layout from '../../layout';
import Sprite from "../../assets/scripts/Sprite";
import Release from '../../components/organisms/release';
import FilterTag from '../../components/atoms/filter-tag';
import AButton from '../../components/atoms/button';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { enterFlip } from '../../assets/scripts/animations';
import useInfiniteScroll from '../../assets/scripts/useInfiniteScroll';
import { SEOTypes } from "../../types";
import useChangeSEO from "../../hooks/useChangeSEO";

import { NotFoundContext } from '../../context/NotFoundContext';
import useNotification from "../../hooks/useNotification";
import debounce from "../../assets/scripts/debounce";
import isEqual from "lodash/isEqual";
import { getWorksData, loadMoreWorksItem, loadWorksData } from "../../features/worksSlise";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";

const dataJSON = require("../../data/works.json");
const Popup = React.lazy(() => import('../../components/organisms/popup'));
const Feedback = React.lazy(() => import("../../components/organisms/feedback"));
const POPUP_FEEDBACK_ID = "feedback_in_works";
const currentLang = process.env.REACT_APP_LANG as keyof typeof dataJSON;
const data = dataJSON[currentLang];

gsap.registerPlugin(ScrollTrigger);

const Works = () => {
    const refBtnWrapper = useRef<HTMLDivElement | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [idList, setIdList] = useState<any>([]);
    const [popupForm, setPopupForm] = useState(false);
    const [context, setContext] = useContext(NotFoundContext);
    const worksData = useSelector(getWorksData);
    const dispatch = useDispatch<Dispatch<any>>();

    useEffect(() => {
        if (popupForm) return;
        const popupId = searchParams.get("popup");
        if (!popupId) return;
        if (popupId === POPUP_FEEDBACK_ID) {
            setPopupForm(true);
        }
    }, [searchParams, popupForm]);

    useLayoutEffect(() => {
        const footerEl = document.querySelector(".footer");
        if (!footerEl) return;

        const changeViewButton = () => {
            if (!refBtnWrapper.current) return;
    
            if (footerEl) {
                if (window.innerWidth >= 768) {
                    if (document.body.scrollHeight - window.innerHeight <= window.pageYOffset) {
                        refBtnWrapper.current.style.bottom = `${footerEl.clientHeight + 16}px`;
                    } else {
                        refBtnWrapper.current.style.bottom = "22px";
                    }
                }
            }
    
            if (window.innerHeight < window.pageYOffset) {
                refBtnWrapper.current.classList.add("works__button-wrp--visible");
            } else {
                refBtnWrapper.current.classList.remove("works__button-wrp--visible");
            }
        };

        if (!refBtnWrapper.current) return;

        window.addEventListener("scroll", debounce(changeViewButton, 16));
        return () => {
            window.removeEventListener("scroll", debounce(changeViewButton, 16));
        }
    }, []);

    const handleMenuOpened = () => {
        const ids = searchParams.get('id') ? searchParams.get('id')?.split(',') : [];

        if (ids) {
            const menus = document.querySelectorAll(`.menu__item`);
            const originPathName = window.location.pathname;
            const menuNavHrefs = Array.from(menus).map(element => element.getAttribute('href'));
            const pathHrefs = menuNavHrefs.filter(href => href?.includes(originPathName));
            const uniqueIds = pathHrefs.filter(href => {
                const url = new URL(href || '', window.location.origin);
                return url.searchParams.get('id');
            }).map(href => {
                const url = new URL(href || '', window.location.origin);
                return url.searchParams.get('id');
            });

            if (ids?.length === 1) {
                uniqueIds.forEach( uniqueId => {
                    if (uniqueId && ids[0] === uniqueId) {
                        menus.forEach(menus => {
                            if (menus.getAttribute('href') === `${originPathName}?id=${uniqueId}`) {
                                menus.classList.add('menu__item--active');
                            } else {
                                menus.classList.remove('menu__item--active');
                            }
                        });
                    }
                });
            } else if (ids?.length === 0 || ids?.length > 1) {
                menus.forEach(menus => {
                    if (menus.getAttribute('href') === `${originPathName}`) {
                        menus.classList.add('menu__item--active');
                    } else {
                        menus.classList.remove('menu__item--active');
                    }
                });
            }
        }
    }

    useEffect(() => {
        if (searchParams.has("popup")) return;

        document.addEventListener('menuOpened', handleMenuOpened);
        const ids = searchParams.get('id') ? searchParams.get('id')?.split(',') : [];

        if (!isEqual(idList, ids)) {
            setIdList(ids);
        }

        if (ids) {
            const headers = document.querySelectorAll(`.header__item`);
            const footers = document.querySelectorAll(`.footer__item`);
            const originPathName = window.location.pathname;
            const headerNavHrefs = Array.from(headers).map(element => element.getAttribute('href'));
            const pathHrefs = headerNavHrefs.filter(href => href?.includes(originPathName));
            const uniqueIds = pathHrefs.filter(href => {
                const url = new URL(href || '', window.location.origin);
                return url.searchParams.get('id');
            }).map(href => {
                const url = new URL(href || '', window.location.origin);
                return url.searchParams.get('id');
            });

            if (ids?.length === 1) {
                uniqueIds.forEach( uniqueId => {
                    if (uniqueId && ids[0] === uniqueId) {
                        headers.forEach(header => {
                            if (header.getAttribute('href') === `${originPathName}?id=${uniqueId}`) {
                                header.classList.add('header__item--active');
                            } else {
                                header.classList.remove('header__item--active');
                            }
                        });

                        footers.forEach(footer => {
                            if (footer.getAttribute('href') === `${originPathName}?id=${uniqueId}`) {
                                footer.classList.add('footer__item--active');
                            } else {
                                footer.classList.remove('footer__item--active');
                            }
                        });
                    }
                });
            } else if (ids?.length === 0 || ids?.length > 1) {
                headers.forEach(header => {
                    if (header.getAttribute('href') === `${originPathName}`) {
                        header.classList.add('header__item--active');
                    } else {
                        header.classList.remove('header__item--active');
                    }
                });

                footers.forEach(footer => {
                    if (footer.getAttribute('href') === `${originPathName}`) {
                        footer.classList.add('footer__item--active');
                    } else {
                        footer.classList.remove('footer__item--active');
                    }
                });
            }
        }

        return () => document.removeEventListener('menuOpened', handleMenuOpened)
    }, [searchParams]);

    const onClosePopup = () => {
        setPopupForm(false);
        searchParams.delete("popup");
        setSearchParams(searchParams);
    }

    gsap.registerPlugin(ScrollToPlugin);

    const addingFiltresBySelectedEl = (e: ChangeEvent<HTMLInputElement>) => {
        let idsArr = [];
        if (idList.includes(e.target.name)) {
            idsArr = idList.filter((item: string) => item !== e.target.name);
        } else {
            idsArr = [...idList, e.target.name];
        }

        setSearchParams(idsArr.length ? {
            id: idsArr.join(','),
        } : {});
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (window.pageYOffset !== 0) {
            ScrollTrigger.matchMedia({
                "(min-width: 1280px)": () => {
                    gsap.to(window, {
                        scrollTo: {
                            y: 0,
                        },
                        duration: .4,
                        onComplete: function() {
                            if (window.pageYOffset === 0) {
                                addingFiltresBySelectedEl(e);
                            }
                        }
                    });
                },
                "(max-width: 1279px)": () => {
                    addingFiltresBySelectedEl(e);
                }
            });
        } else {
            let idsArr = [];
            if (idList.includes(e.target.name)) {
                idsArr = idList.filter((item: string) => item !== e.target.name);
            } else {
                idsArr = [...idList, e.target.name];
            }

            setSearchParams(idsArr.length ? {
                id: idsArr.join(','),
            } : {});
        }
    }

    const togglePopupForm = (e: any) => {
        !popupForm ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
        setPopupForm(!popupForm);
        searchParams.set('popup', POPUP_FEEDBACK_ID);
        setSearchParams(searchParams);
    }

    const animation = () => {
        setTimeout(() => {
            enterFlip('.release__item:not(.js-init-card)', '.release');
            const items = Array.from(document.querySelectorAll('.works .release .release__item'));
            items.forEach(item => item.classList.add('js-init-card'));
        }, 0);
    };

    const loadMoreItems = () => {
        dispatch(loadMoreWorksItem({
            items: worksData.items,
            idList,
            onSuccess: () => {
                animation();
            },
            onEndLoadItems: () => {
                setScrollOn(false);
            }
        }))
    }

    const [setScrollOn] = useInfiniteScroll(loadMoreItems);

    useEffect(() => {
        const idsArray = searchParams.get('id')?.split(',') || [];
        if (isEqual(worksData.usedFiltres, idsArray) && worksData.items.length > 0) return;

        dispatch(loadWorksData({
            idList,
            onSuccess: () => {
                setScrollOn(true);
            },
            onLoadedFilters: (data) => {
                if (idsArray?.length) {
                    const filterTags = data.filter.map((obj: any) => obj?.name ? obj.name : '');
                    const idsArrayCopy = idsArray.filter((id) => filterTags.includes(id));

                    if (idsArray.length !== idsArrayCopy.length) {
                        setSearchParams(idsArrayCopy?.length ? {
                            id: idsArrayCopy.join(','),
                        } : {});
                        return
                    }
                }
            },
            onItemsLoaded: () => {
                animation();
            },
            onError: () => {
                setContext(true);
            }
        }));
    }, [idList]);

    const seoData: Partial<SEOTypes> = {
        description: worksData.description,
        title: worksData.title,
        ...worksData.seo,
    }

    useChangeSEO({data: seoData});

    const { renderMessage, setNotificationData } = useNotification({ timeView: 10000 });

    return (
        <>
            {popupForm && data.popup_form && <Suspense fallback={<div></div>}>
                    <Popup isShow={!popupForm} className="header__popup" type='form' onClose={onClosePopup}>
                        <Feedback
                            {...data.popup_form}
                            form={data.popup_form.form}
                            title={worksData?.popup_form?.title || ""}
                            subtitle={worksData?.popup_form?.subtitle || ""}
                            onClose={onClosePopup}
                            onNotification={({ notification }) => setNotificationData(notification)}
                        />
                    </Popup>
                </Suspense>
            }
                <div className='works'>
                    {renderMessage}
                    <span className='gradient-radial gradient-radial--light-blue works__gradient works__gradient--mr'></span>
                    {worksData && <section className="works__head container">
                        {worksData.title && typeof worksData.title === 'string' && <h1 className="caption-7">{Parser(worksData.title || '')}</h1>}
                        {worksData.description && typeof worksData.description === 'string' && <div className="works__description text-1">{Parser(worksData.description)}</div>}
                    </section>}
                    <div className='works__list container'>
                        {!!worksData.filter?.length && (
                            worksData.filter.map((tag: any, index: number) => (
                                <FilterTag key={`filter-tag-${index}`} {...tag} checked={(idList.includes(tag.name))} onChange={onChange} />
                            ))
                        )}
                    </div>
                    <div className='works__bg'>
                        <LazyLoadImage src='/assets/images/planet.png' alt='planet with gradient mask'/>
                    </div>
                    <Release className='works__items' items={worksData.items} isCardAnimated={true}/>
                    <div ref={refBtnWrapper} className='works__button-wrp'>
                        <AButton className='works__button' onClick={togglePopupForm} text={worksData.text}/>
                    </div>
                </div>
            <Sprite />
        </>
    );
}

export default Works;
