import React from 'react';

export type AIconType = {
	className?: string;
	style?: React.CSSProperties;
	name: string;
	title?: string;
}

const AIcon = (props: AIconType) => {
	return (
		<svg style={{...props.style} || {}} className={`icon ${props.className || ""}`} preserveAspectRatio="xMidYMid meet">
			<use xlinkHref={`#${props.name}`}/>
			{props.title && typeof props.title === 'string' && <title>{props.title}</title>}
		</svg>
	);
};

export default AIcon;
