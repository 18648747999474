import './style.scss';
import React, {useState, Suspense} from 'react';
import Parser from 'html-react-parser';
import AIcon from '../../atoms/icon';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cn from 'classnames';
import { MouseEvent } from 'react';

export type CardReviewType = {
    loadMore?: string,
    isOnly?: boolean,
    company: {
        src: string,
        name: string,
    },
    client: {
        src: string,
        name: string,
        position: string,
    },
    text: string,
    link?: {
        to: string,
        text: string
    },
    descriptionFullView?: boolean,
    togglePopup: (e: MouseEvent, data?: any) => void,
}

const CardReview = (props: CardReviewType) => {
    const button = {
        text: props.loadMore,
        icon: "review"
    }
    const togglePopup = (e: MouseEvent) => {
        props.togglePopup(e, {company:props.company, client:props.client,text:props.text });
    }
    return (
        <div className={cn('card-review', {
            'card-review--align-center' : props?.isOnly,
            'card-review--full-view': props?.descriptionFullView,
        })}>
            <div className='card-review__heading'>
                {props.company.src && <LazyLoadImage className='card-review__logo' src={props.company.src} alt={`logo of ${props.company.name}`}/>}
                {props.client.src && <LazyLoadImage className='card-review__logo' src={props.client.src} alt={`avatar of ${props.client.name}`} />}
                <div className='card-review__wrapper'>
                    {props.client.name && typeof props.client.name === 'string' && <span className='text-4 card-review__title'>{props.client.name}</span>}
                    <span className='text-2 card-review__description' >{typeof props.client.position === 'string' ? props.client.position : ''}, {typeof props.company.name === 'string' ? props.company.name : ''}</span>
                </div>
            </div>
            {props.descriptionFullView
                ? (
                    <div className='text-6 card-review__text'>{Parser(props.text)}</div>
                )
                : (
                    <>
                        <div className='text-6 card-review__text'>
                            {props.text && typeof props.text === 'string' && (props.text.length > 250 ? Parser(`${props.text.slice(0, 250)}&nbsp;...`) : Parser(props.text))}
                        </div>
                        {props.text && typeof props.text === 'string' && props.text.length > 250 &&
                            <button
                                className='text-2 card-review__button'
                                aria-label='button read more'
                                onClick={togglePopup}
                            >
                                <AIcon className='card-review__button-icon' name={button.icon}/>{typeof button.text === 'string' ? button.text : ''}
                            </button>
                        }
                    </>
                )
            }
        </div>
    );
};

export default CardReview;
