import './style.scss';
import React, {useContext, useState, Suspense, useEffect, useMemo} from 'react';
import { request } from '../../api/api';
import Parser from 'html-react-parser';
import {useSearchParams } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Layout from '../../layout';
import Introduction from '../../components/organisms/introduction';
import { CardReviewType } from '../../components/molecules/card-review';
import { CardEventType } from '../../components/molecules/card-event';
import { CardServiceType } from '../../components/molecules/card-service';
import ShowreelOpener from '../../components/molecules/showreel-opener';
import main from '../../data/main.json';
import Sprite from "../../assets/scripts/Sprite";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { transformCard, clearTransform } from '../../assets/scripts/animations';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import useChangeSEO from "../../hooks/useChangeSEO";
import useNotification from "../../hooks/useNotification";
import { NotFoundContext } from '../../context/NotFoundContext';
import ymTrigers from "../../configs/ymTrigers";
import Footer from "../../components/organisms/footer";
import shuffle from "lodash/shuffle";
import fixedUrlTo from "../../assets/scripts/fixedUrlTo";

const Slider = React.lazy(() => import('../../components/organisms/slider'));
const Release = React.lazy(() => import('../../components/organisms/release'));
const Subscribe = React.lazy(() => import('../../components/organisms/subscribe'));
const CardReview = React.lazy(() => import('../../components/molecules/card-review'));
const CardEvent = React.lazy(() => import('../../components/molecules/card-event'));
const CardService = React.lazy(() => import('../../components/molecules/card-service'));
const Feedback = React.lazy(() => import("../../components/organisms/feedback"));
const Popup = React.lazy(() => import('../../components/organisms/popup'));
const AButton = React.lazy(() => import('../../components/atoms/button'));
const ALink = React.lazy(() => import('../../components/atoms/link'));
const Notification = React.lazy(() => import("../../components/molecules/notification"));

const POPUP_FEEDBACK_ID = "feedback_in_main-page";
const urlByStages = process.env.REACT_APP_STAGE === "integration" ? "getPageMain/" : '/ajax/main.json';

const Main = () => {
    const baseData = (window as any).INIT_DATA;
    const [popupGift, setPopupGift] = useState(false);
    const [ajaxData, setAjaxData] = useState<any>({});
    const [popupForm, setPopupForm] = useState(false);
    const [context, setContext] = useContext(NotFoundContext);
    let [searchParams, setSearchParams] = useSearchParams();
    const [popupComment, setPopupComment] = useState({
        isView: false,
        data: {
            company: {
                src:"",
                name:""
            },
            client: {
                src:"",
                name:"",
                position: ""
            },
            text: ""
        },
    });

    useEffect(() => {
        if (popupForm) return;
        const popupId = searchParams.get("popup");
        if (!popupId) return;
        if (popupId === POPUP_FEEDBACK_ID) {
            setPopupForm(true);
        }
    }, [searchParams]);

    const onClosePopup = () => {
        setPopupForm(false);
        searchParams.delete("popup");
        setSearchParams(searchParams);
    }

    useEffect(() => {
        request({
            method: "GET",
            url: urlByStages,
            params: {
                lang: process.env.REACT_APP_LANG
            },
        }).then(response => {
            setAjaxData(response.data);
        }).catch(() => {
            setContext(true);
        });
    }, []);

    const togglePopupForm = (e: any) => {
        setPopupGift(false)
        !popupForm ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
        setPopupForm(!popupForm);
        setSearchParams({
            popup: POPUP_FEEDBACK_ID,
        });
    }

    // @ts-ignore
    const [data] = useState<FooterState>(main[process.env.REACT_APP_LANG]);

    const metrika = () => {
        ymTrigers.phoneNumberClick();
    }

    const {
        setNotificationData, notificationData, onCloseNotification
    } = useNotification({ timeView: 10000 });

    useChangeSEO({ data: ajaxData?.seo });

    const randomedReviewItems = useMemo(() => shuffle(ajaxData?.reviews?.items || []), [ajaxData?.reviews?.items])

    return (
        <>
            {data.popup_gift && popupGift &&
                <Suspense fallback={<></>}>
                    <Popup isShow={popupGift} type='gift' onClose={() => setPopupGift(false)} >
                        <div className='popup__heading'>
                            {data.popup_gift.title && typeof data.popup_gift.title === 'string' && <h3 className='caption-3'>{Parser(data.popup_gift.title)}</h3>}
                            {data.popup_gift.text && typeof data.popup_gift.text === 'string' && <span className='text-2'>{Parser(data.popup_gift.text)}</span>}
                        </div>
                        <div className='popup__controls'>
                            {Array.isArray(data.popup_gift.controls) && data.popup_gift.controls.map((item: any) => (
                                item.subtext
                                    ? <AButton key={item.text} text={item.text} subtext={item.subtext} onClick={togglePopupForm}/>
                                    : process.env.REACT_APP_LANG === 'ru'
                                        && baseData?.tel && <ALink key={item.text} type='no-bg' text={item.text} subtext={baseData.tel} href={`tel:${baseData.tel}`} onClick={metrika}/>
                            ))}
                        </div>
                    </Popup>
                </Suspense>
            }
            {popupForm &&
                <Suspense fallback={<></>}>
                    <Popup isShow={!popupForm} className="header__popup" type='form' onClose={onClosePopup}>
                        <Feedback
                            {...data.popup_form}
                            form={data.popup_form.form}
                            title={ajaxData?.popup_form?.title || ""}
                            subtitle={ajaxData?.popup_form?.subtitle || ""}
                            onNotification={({ notification }) => setNotificationData(notification)}
                            onClose={onClosePopup}
                            data={{ gift: true }}
                        />
                    </Popup>
                </Suspense>
            }
            <div className='main'>
                <Introduction showPopup={() => setPopupGift(true)}/>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350} offset={500}>
                        {ajaxData && ajaxData.services && <Slider
                            sliderClass='container__inner'
                            slidesPerView={4}
                            heading={{
                                title: ajaxData.services.title,
                                control: Object.assign({text: ajaxData.services?.btnText}, ajaxData.services.control, data.services.control),
                                className: 'main__heading'
                            }}
                            items={[...ajaxData.services.items, ...data.services.items]}
                            render={(i: CardServiceType) => (
                                <CardService
                                    {...i}
                                    description=""
                                    mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)}
                                    mouseLeave={(card: HTMLElement) => clearTransform(card)}
                                />
                            )}
                        />}
                    </LazyLoad>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350} offset={500}>
                        {ajaxData && ajaxData.release &&
                            <Release
                                className="js-stop-element-for-planet"
                                heading={{
                                    title: ajaxData.release.title,
                                    control: Object.assign({text: ajaxData.release?.link?.title, to: ajaxData.release?.link?.url}, ajaxData.release?.link, data.release.control),
                                }}
                                items={ajaxData.release.items}
                                link={{
                                    text: ajaxData.release?.link?.title,
                                    to: ajaxData.release?.link?.url,
                                    ...Object.assign(data.release.link, ajaxData.release?.link)
                                }}
                            />
                        }
                    </LazyLoad>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350} offset={500}>
                        {ajaxData && ajaxData.projects && <Release
                            heading={{
                                title: ajaxData.projects.title,
                                description: ajaxData.projects.description,
                                control: Object.assign({text: ajaxData.projects?.link?.title, to: ajaxData.projects?.link?.url}, ajaxData.projects?.link, data.projects.control),
                            }}
                            items={ajaxData.projects.items}
                            link={{
                                text: ajaxData.projects?.link?.title,
                                to: ajaxData.projects?.link?.url,
                                ...Object.assign(data.projects.link, ajaxData.projects?.link)
                            }}
                            video={ajaxData?.projects?.video}
                            grid="card-grid-3"
                        />}
                        <section className='main__design'>
                            <span className='main__gradient gradient-radial gradient-radial--red'></span>
                            <span className='main__gradient gradient-radial gradient-radial--red'></span>
                            <span className='main__gradient gradient-radial gradient-radial--red'></span>
                            <span className='main__gradient gradient-radial gradient-radial--blue'></span>
                        </section>
                    </LazyLoad>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350} offset={500}>
                        {ajaxData && ajaxData.reviews &&
                        <>
                            <Slider
                                heading={{title: ajaxData.reviews.title}}
                                items={randomedReviewItems}
                                custom={true}
                                render={(i: CardReviewType) => (
                                    <CardReview
                                        {...i}
                                        loadMore={data.reviews.text}
                                        togglePopup={(e, data) => {
                                            !popupComment ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
                                            setPopupComment({isView:true, data});
                                        }}
                                    />
                                )}
                            />
                            {popupComment.isView &&
                                <Suspense fallback={<div></div>}>
                                    <Popup className="popup__popup" type='comment' isShow={popupComment.isView}
                                    onClose={() => setPopupComment({...popupComment,isView:false})}>
                                        <div>
                                            <div className='popup__heading-review'>
                                                <div>
                                                {popupComment.data.company.src && <LazyLoadImage className='popup__logo' src={popupComment.data.company.src} alt={`logo of ${popupComment.data.company.name}`}/>}
                                                {popupComment.data.client.src && <LazyLoadImage className='popup__logo' src={popupComment.data.client.src} alt={`avatar of ${popupComment.data.client.name}`} />}
                                                </div>
                                                <div className='popup__wrapper-review'>
                                                    {popupComment.data.client.name && typeof popupComment.data.client.name === 'string' && <span className='text-4 popup__title'>{popupComment.data.client.name}</span>}
                                                    <span className='text-2 popup__description' >{typeof popupComment.data.client.position === 'string' ? popupComment.data.client.position : ''}, {typeof popupComment.data.company.name === 'string' ? popupComment.data.company.name : ''}</span>
                                                </div>
                                            </div>
                                            {popupComment.data.text && typeof popupComment.data.text === 'string' && (
                                                <div className='text-6 popup__text'>
                                                    {Parser(popupComment.data.text)}
                                                </div>
                                            )}
                                            <button className='popup__button popup__button--review-close' data-close="true" aria-label="close button">
                                                {data.reviews.button}
                                            </button>
                                        </div>
                                    </Popup>
                                </Suspense>
                            }
                        </>
                        }
                    </LazyLoad>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350} offset={500}>
                        {ajaxData && ajaxData.events && <Slider className='main__slider'
                            heading={{
                                title: ajaxData.events.title,
                                control: Object.assign({text: ajaxData.events?.link?.title, to: ajaxData.events?.link?.url}, data.events.control, ajaxData.events?.link),
                            }}
                            items={ajaxData.events.items}
                            render={(i: CardEventType) => <CardEvent {...i} src={i?.images?.medium || ""} to={fixedUrlTo(`/blogs/${i.id}`)} />}
                        />}
                    </LazyLoad>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350}>
                        {ajaxData && ajaxData.subscribe && <Subscribe heading={{
                                title: ajaxData.subscribe.title,
                        }} items={ajaxData.subscribe.items} {...data.subscribe}/>}
                    </LazyLoad>
                </Suspense>
                {ajaxData && ajaxData.video &&
                    <ShowreelOpener {...ajaxData.video}/>
                }
                {notificationData.text && <Suspense fallback={<div></div>}>
                    <Notification
                        text={notificationData.text}
                        type={notificationData.type}
                        onClose={onCloseNotification}
                    />
                </Suspense>
                }
                <Footer />
            </div>
            <Sprite />
        </>
    );
}

export default Main;
