import variablesSCSS from '../../../styles/_min-media.module.scss';
import React, { useEffect, useMemo, useRef } from 'react';
import {Swiper as SwiperInst, SwiperSlide, SwiperProps} from 'swiper/react';
import {Navigation, Pagination, Swiper as SwiperType} from 'swiper';
import cn from 'classnames';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.scss';
import debounce from "../../../assets/scripts/debounce";

type BaseSliderTypes<IT> = {
  dataItems: IT[],
  options: SwiperProps,
  renderCard: Function,
  isMobileView: boolean,
  className: string,
  viewNavigation: boolean,
}

const desktopSize = Number((variablesSCSS.t).replace("px", '')) || 1024;

const BaseSlider = <IT,>({
  options,
  renderCard,
  dataItems,
  isMobileView,
  className,
  viewNavigation,
}: BaseSliderTypes<IT>) => {
  const swiperOptions = useRef<SwiperProps>({});
  const swiperRef = useRef<SwiperType>();
  
  useMemo(() => {
    if (isMobileView) {
      swiperOptions.current = {
        slidesPerView: "auto",
        breakpoints: {
          [desktopSize]: {
            init: false,
            slidesPerView: 3,
          }
        },
      }
    }
    
    swiperOptions.current = { ...swiperOptions.current, ...options};
  }, [isMobileView, options]);

  const onChangeViewSlider = debounce(() => {
    if (!swiperRef.current) return;
    if (window.innerWidth >= desktopSize) {
      return swiperRef.current.destroy(false, true);
    } 
    if (window.innerWidth < desktopSize) {
      swiperRef.current.init();
      swiperRef.current.destroyed = false;
      swiperRef.current.slideTo(0);
      swiperRef.current.update();
      swiperRef.current.pagination.update();
      return;
    }
  }, 100);

  useEffect(() => {
    if (isMobileView) {
      onChangeViewSlider();
      window.addEventListener('resize', onChangeViewSlider);
      return () => {
        window.removeEventListener('resize', onChangeViewSlider);
      }
    }
  }, [onChangeViewSlider, isMobileView]);
  
  if (!dataItems) return null;

  return (
    <SwiperInst
      className={cn("base-slider", className, {
        "base-slider--mobile-view": isMobileView,
      })}
      onSwiper={(swiper) => swiperRef.current = swiper}
      modules={[Navigation, Pagination]}
      slidesPerView={1}
      spaceBetween={16}
      pagination={{
        el: '.base-slider__pagination',
        type: 'bullets',
        clickable: true,
      }}
      breakpoints={{
        [desktopSize]: {
          spaceBetween: 32,
        }
      }}
      updateOnWindowResize
      observeParents
      observer
      {...swiperOptions.current}
    >
      {dataItems.map((item, n) => (
        <SwiperSlide key={`swiper-item-` + n} className="base-slider__slide">
          {renderCard(item)}
        </SwiperSlide>
      ))}
      {viewNavigation && (
        <div
          className="base-slider__navigation"
        >
          <div className="base-slider__pagination swiper-pagination" />
        </div>
      )}
    </SwiperInst>
  );
};

BaseSlider.defaultProps = {
  options: {},
  renderCard: () => {},
  isMobileView: false,
  className: "",
  viewNavigation: true,
}

export default BaseSlider;
