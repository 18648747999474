import './style.scss';
import React, {useState, MouseEvent, useEffect, useMemo, useRef} from 'react';
import AIcon from '../../atoms/icon';
import {gsap, ScrollTrigger, Back} from 'gsap/all';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

gsap.registerPlugin(ScrollTrigger);

export type ShowreelOpenerType = {
    className?: string,
    src?: string,
    poster?: string,
}

const loadYTApi = () => {
    return new Promise<void>((resolve) => {
        //@ts-ignore
        if (window.YT) {
            resolve();
        } else {
            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";

            const firstScriptTag = document.getElementsByTagName('script')[0];

            if (firstScriptTag.parentNode) {
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }

            //@ts-ignore
            window.onYouTubeIframeAPIReady = () => {
                resolve();
            };
        }
    });
};

const parseYtId = (url: string | undefined) => {
    if (!url) {
        return ''
    }

    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);

    return (match&&match[7].length==11) ? match[7] : '';
}

const ShowreelOpener = (props: ShowreelOpenerType) => {
    const [tl, setTl] = useState<gsap.core.Timeline>();
    const [ref, setRef] = useState<HTMLVideoElement | null>(null);
    const [ytId, setYtId] = useState(parseYtId(props.src))
    const [ytPlayer, setYtPlayer] = useState();
    const ytRef = useRef(null);
    const opener = React.useRef<HTMLDivElement>(null);

    const onClick = (e: MouseEvent) => {
        if (!tl || !opener.current) return;

        opener.current.classList.add('showreel-opener--opened');

        const header = document.querySelector('.header');
        if (header) {
            header.classList.add('header--showreel-opened');
        }

        if (ytId) {
            if (!ytPlayer) {
                loadYTApi().then(() => {
                    setYtPlayer(
                        //@ts-ignore
                        new YT.Player(ytRef.current, {
                            width: '100%',
                            height: '100%',
                            videoId: ytId,
                            events: {
                                //@ts-ignore
                                onReady(event) {
                                    event.target.playVideo();
                                }
                            }
                        })
                    );
                })
            } else {
                //@ts-ignore
                ytPlayer.playVideo();
            }
        }

        if (ref) {
            if (!ref.hasAttribute('src')) {
                ref.setAttribute('src', `${props.src}`);
            }
        }

        disableBodyScroll(opener.current, {
            reserveScrollBarGap: true,
        });

        tl.seek(0).invalidate().restart();
        ref?.play();
    }

    const closeShowreel = (e: MouseEvent) => {
        e.stopPropagation();
        if (!tl) return;

        tl.reverse();

        if (opener.current) {
            opener.current.classList.remove('showreel-opener--opened');
        }

        const header = document.querySelector('.header');
        if (header) {
            header.classList.remove('header--showreel-opened');
        }

        if (ytPlayer) {
            //@ts-ignore
            ytPlayer.pauseVideo();
        }

        clearAllBodyScrollLocks();

        ref?.pause();
    }

    const openTl = useMemo(() => gsap.timeline({
        paused: true,
    }), []);

    useEffect(() => {
        const elem = opener.current;
        if (!elem) return;
        let showreelTl = gsap.timeline();
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                if (showreelTl.labels.loadded !== undefined) return;
                showreelTl = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: '.introduction',
                        pinnedContainer: '.showreel-opener',
                        start: 'top top',
                        end: `top+=${window.innerHeight * 5}`,
                        scrub: 0.5
                    },
                }).addLabel('loadded').fromTo('.showreel-opener__button', {
                    opacity: 0,
                    pointerEvents: 'none'
                }, {
                    opacity: 0,
                    pointerEvents: 'all',
                    duration: 0.01,
                }).fromTo('.showreel-opener__button', {
                    top: gsap.getProperty('.showreel-opener__button', 'top'),
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                    opacity: 0
                }, {
                    top: window.innerHeight / 2,
                    opacity: 1,
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                    duration: 0.15,
                }).fromTo('.showreel-opener__button', {
                    top: window.innerHeight / 2,
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                }, {
                    top: 100,
                    duration: 0.9,
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                    ease: Back.easeInOut.config(1.7),
                }).fromTo('.showreel-opener__button', {
                    opacity: 1,
                    top: 100,
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                }, {
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                    top: 100,
                    opacity: 0,
                }).fromTo('.showreel-opener__button', {
                    opacity: 0
                }, {
                    pointerEvents: 'none',
                    opacity: 0,
                    duration: 0.01,
                });

                return () => {
                    showreelTl.kill();
                    showreelTl = gsap.timeline();
                    gsap.set('.showreel-opener__button', {
                        clearProps: 'all'
                    });
                }
            },
            "(max-width: 767px)": () => {
                showreelTl.progress(0).pause();
                return () => {
                    showreelTl.play();
                }
            }
        });
        if (openTl.labels.loadded !== undefined) return;
        openTl.addLabel('loadded').fromTo('.showreel', {
            width: Number(gsap.getProperty('.showreel-opener__button', 'width')),
            height: Number(gsap.getProperty('.showreel-opener__button', 'height')),
            top: () => Number(gsap.getProperty('.showreel-opener__button', 'top')),
            left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
            scale: 0,
            opacity: 0,
        }, {
            width: () => Number(gsap.getProperty('.showreel-opener__button', 'width')),
            height: () => Number(gsap.getProperty('.showreel-opener__button', 'height')),
            top: () => Number(gsap.getProperty('.showreel-opener__button', 'top')),
            left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
            scale: 1,
            opacity: 1,
            ease: 'linear'
        }).fromTo('.showreel', {
            borderRadius: '50%',
        }, {
            borderRadius: 0,
            ease: 'linear',
        }).fromTo('.showreel', {
            width: () => Number(gsap.getProperty('.showreel-opener__button', 'width')),
            height: () => Number(gsap.getProperty('.showreel-opener__button', 'height')),
            top: () => Number(gsap.getProperty('.showreel-opener__button', 'top')),
            left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
        }, {
            width: () => '100%',
            height: () => '100%',
            top: () => 0,
            left:() => 0,
            ease: 'linear',
        }, '<').fromTo('.showreel__close', {
            opacity: 0
        }, {
            opacity: 1
        }).duration(0.6);
        setTl(openTl);
    }, []);

    return (
        <>
            <div ref={opener} className={['showreel-opener', props.className ? props.className : ''].join(' ')}>
                <div className='showreel-opener__button' onClick={(e) => onClick(e)}>
                    <LazyLoadImage className='showreel-opener__image' src='/assets/images/showreel-opener.png' alt="alien's face"/>
                    <AIcon className='showreel-opener__icon' name='play'/>
                </div>
                <div className="showreel">
                    {props.src && typeof props.src === 'string' && (
                        ytId
                        ?
                        <div className="showreel__video"><div ref={ytRef} id="showreel-yt-video"></div></div>
                        :
                        <video ref={setRef} className="showreel__video" preload="auto" poster={props.poster} playsInline={true} ></video>
                    )}
                    <div className="showreel__close" onClick={(e) => closeShowreel(e)}>
                        <AIcon name="close" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShowreelOpener;
