import { useEffect, useState} from "react";

const useInfiniteScroll = (callback: () => void) => {
    const [scrollOn, setScrollOn] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            if (!scrollOn) return;

            if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                callback();
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [callback, scrollOn]);

    return [setScrollOn] as const;
};

export default useInfiniteScroll;
