const fixedUrlTo = (to) => {
  if (!to && typeof to !== "string") return "#";

  const { searchParams } = new URL(to, window.location.origin);
  const paramsKeys = [];
  for (const p of searchParams.keys()) {
      paramsKeys.push(p);
  }

  if (paramsKeys.length || to[to.length - 1] === "/") {
      return to;
  } else {
      return to + "/";
  }
}

export default fixedUrlTo;
