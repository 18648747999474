import React, { useEffect, useRef } from 'react';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import NavigationLink from '../../atoms/navigation-link';

import './style.scss';
import fixedUrlTo from "../../../assets/scripts/fixedUrlTo";

type MenuType = {
    isOpen: boolean,
    clickNav?: () => void
}

type MenuState = {
    logo: {
        to: string,
        src: string,
        alt: string
    },
    nav: [
        {
            to?: string,
            text: string
        }
    ],
    mail: {
        href?: string,
        text: string
    },
    tel: {
        href?: string,
        text: string
    },
    lang?: {
        href?: string,
        text: string
    },
    copyright: string
}

const Menu = (props: MenuType) => {
    // @ts-ignore
    const menu: MenuState = window.INIT_DATA;
    const menuRef = useRef(null);


    useEffect(() => {
        const element = menuRef.current;

        if (props.isOpen) {
            if (!(element === null)) {
                disableBodyScroll(element as HTMLElement);

                const eventMenuOpened = new Event('menuOpened');
                document.dispatchEvent(eventMenuOpened);
            }
        }


        return () => {
            if (!(element === null)) {
                enableBodyScroll(element as HTMLElement);
            }
        };
    }, []);

    if (!menu) return null;

    return (
        <div className='menu'>
            <div className='menu__wrapper' ref={menuRef}>
                <nav className='menu__nav'>
                    {Array.isArray(menu.nav) && menu.nav.map((item, i) => (
                        <NavigationLink
                            className='text-4 menu__item'
                            activeClass='menu__item--active'
                            to={fixedUrlTo(item.to)}
                            text={item?.text || ''}
                            key={`nav-${i}`}
                            onClick={props.clickNav}
                        />
                    ))}
                </nav>
                <div className='menu__controls'>
                    {menu.tel && typeof menu.tel === 'string' && <a aria-label='link phone number' className='text-2' href={`tel:${menu.tel}`}>{menu.tel}</a>}
                    {menu.lang && <a aria-label='change language' className='text-2' href={menu.lang.href}>{typeof menu.lang.text === 'string' ? menu.lang.text : ''}</a>}
                </div>
            </div>
        </div>
    );
};

export default Menu;
