import './styles.scss';
import React, { useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import AIcon from "../../atoms/icon";

type Items = {
  icon: string,
  code: 'vkontakte' | 'facebook',
}

type SharingProps = {
  className?: string,
  items: Array<Items>,
}

const Sharing = ({
  className,
  items,
}: SharingProps) => {
  if (!items.length) return null;

  const getShareData = () => {
    return {
      title: document.head.querySelector("meta[property=og\\:title]")?.getAttribute("content") || "",
      description: document.head.querySelector("meta[property=og\\:description]")?.getAttribute("content") || "",
      image: document.head.querySelector("meta[property=og\\:image]")?.getAttribute("content") || "",
    }
  }

  const share = {
    vkontakte: () => {
      const shareData = getShareData();
      let shareUrl  = 'http://vkontakte.ru/share.php?';
      shareUrl += 'url='          + encodeURIComponent(window.location.href);
      shareUrl += '&title='       + encodeURIComponent(shareData.title);
      shareUrl += '&description=' + encodeURIComponent(shareData.description);
      shareUrl += '&image='       + encodeURIComponent(shareData.image);
      shareUrl += '&noparse=true';
      share.popup(shareUrl);
    },
    facebook: () => {
      const shareData = getShareData();
      let shareUrl  = 'http://www.facebook.com/sharer.php?s=100';
      shareUrl += '&p[title]='     + encodeURIComponent(shareData.title);
      shareUrl += '&p[summary]='   + encodeURIComponent(shareData.description);
      shareUrl += '&p[url]='       + encodeURIComponent(window.location.href);
      shareUrl += '&p[image]=' + encodeURIComponent(shareData.image);
      share.popup(shareUrl);
    },
  
    popup: function(url: string) {
      window.open(url,'','');
    }
  };

  return (
    <div className={cn("sharing", className)}>
      {items.map((item) => {
        return (
          <button key={item.icon} onClick={share[item.code]}>
            <AIcon className="sharing__icon" name={item.icon} />
          </button>
        )
      })}
    </div>
  );
};

export default Sharing;
