import './styles.scss';
import React from 'react';
import ALink from "../../atoms/link";
import cn from 'classnames';
import fixedUrlTo from "../../../assets/scripts/fixedUrlTo";

type BreadcrumbsProps = {
  items: Array<{
    text: string,
    to: string,
  }>,
  className?: string,
}

const Breadcrumbs = ({
  items,
  className,
}: BreadcrumbsProps) => {
  if (items && items.length) {
    return (
      <div className={cn("breadcrumbs", className)}>
        {items.map((elem, i:number) => (
          <ALink
            key={`breadcrumbs-${i}`}
            text={elem.text}
            to={fixedUrlTo(elem.to)}
          />
        ))}
      </div>
    )
  }

  return null;
};

export default Breadcrumbs;
