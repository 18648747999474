import './styles.scss';
import React, {useContext, useState, useEffect, Suspense, useRef, useLayoutEffect, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import Sprite from "../../assets/scripts/Sprite";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import gsap, {ScrollTrigger} from 'gsap/all';
import { itemsUpViewDownHide, parallaxEffect } from '../../assets/scripts/animations';
import LazyLoad from "react-lazyload";
import Layout from '../../layout';
import Heading from '../../components/molecules/heading';
import { request } from "../../api/api";
import { SEOTypes } from "../../types";
import useChangeSEO from "../../hooks/useChangeSEO";
import { NotFoundContext } from '../../context/NotFoundContext';
import useNotification from "../../hooks/useNotification";
import { ImageType } from "../../components/atoms/image";
import cn from "classnames";

const InlineHtmlViewer = React.lazy(() => import("../../components/organisms/inline-html-viewer"));
const CardReview = React.lazy(() => import('../../components/molecules/card-review'));
const CardRelease = React.lazy(() => import('../../components/molecules/card-release'));
const Feedback = React.lazy(() => import('../../components/organisms/feedback'));

const dataJSON = require('../../data/detailWorks.json');
const currentLang = process.env.REACT_APP_LANG as keyof typeof dataJSON;
gsap.registerPlugin(ScrollTrigger);

const urlByStages = process.env.REACT_APP_STAGE === "integration" ? "getPageWork/" : '/ajax/works-detail.json';

const DetailWorks = () => {
  const [refDetailWork, setRefDetailWork] = useState<HTMLDivElement | null>(null);
  const [ajaxData, setAjaxData] = useState<any>({});
  const [contentLoaded, setContentLoaded] = useState(false);
  const [context, setContext] = useContext(NotFoundContext);
  const { works_id } = useParams();

  const getRefParent = useCallback((ref: any) => {
    if (!ref) return;
    setRefDetailWork(ref);
  }, []);

  useEffect(() => {
    setContentLoaded(false);

    request({
      method: "GET",
      url: urlByStages,
      params: {
        lang: process.env.REACT_APP_LANG,
        code: works_id,
      }
    }).then(response => {
      setAjaxData(() => response.data);
      setTimeout(() => {
        setContentLoaded(true);
      }, 0);
    }).catch(() => {
      setContentLoaded(false);
      setContext(true);
    });
  }, [works_id]);

  const projectRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!refDetailWork) return;

    let animItems: any = [];

    const timeId = setInterval(() => {
      const elements = [].slice.call(refDetailWork.querySelectorAll('.detail-works__html-content > *'));
      if (elements.length > 0) {
        clearInterval(timeId);

        animItems = itemsUpViewDownHide(elements);
      }
    }, 250);

    return () => {
      clearInterval(timeId);
      animItems?.forEach((anim: any) => anim.kill);
    }
  }, [refDetailWork]);

  const seoData: Partial<SEOTypes> = {
    description: ajaxData.description,
    title: ajaxData.title,
    ...ajaxData.seo,
  }

  useChangeSEO({data: seoData});

  const setAnimate = (elem: string) => {
    parallaxEffect(elem);
  }
  const { setNotificationData, renderMessage } = useNotification({ timeView: 10000 });
  const data = dataJSON[currentLang];

  if (!data) return null;

  return (
    <Suspense>
        {renderMessage}
        <div className="detail-works" key={works_id} ref={getRefParent}>
          <Sprite />
            <div className={cn('detail-works-container container', {
              "detail-works-container--is-loaded": contentLoaded,
            })}>
              {contentLoaded && <Heading
                title={ajaxData.title}
                description={ajaxData.description}
                unit={ajaxData.label}
                theme='light'
                titleType="caption-3"
                control={Object.assign({text: ajaxData.projectLink?.text, href: ajaxData.projectLink?.href}, data.heading.control)}
                className='container detail-works__heading'
              />}
              {contentLoaded && ajaxData.html ?
                <InlineHtmlViewer
                  className="detail-works__html-content"
                  inlineHTML={ajaxData.html}
                />
                : <></>
              }
              {contentLoaded && ajaxData.review &&
                <section className='container detail-works__reviews'>
                  {data.reviews.title && typeof data.reviews.title === 'string' && <h3 className='caption-9 detail-works__title'>{data.reviews.title}</h3>}
                  <CardReview
                    {...ajaxData.review}
                    className='detail-works__review'
                    isOnly={true}
                    descriptionFullView
                  />
                </section>
              }
              {contentLoaded && data && (
                <section className='container detail-works__feedback'>
                  <Feedback
                    {...data}
                    title={ajaxData?.feedback?.title || ""}
                    subtitle={ajaxData?.feedback?.subtitle || ""}
                    onNotification={({notification}) => setNotificationData(notification)}
                    className="feedback--on-page"
                  />
                </section>
              )}

              {contentLoaded && Array.isArray(ajaxData.projects) &&
                <section className='container detail-works__projects' ref={projectRef}>
                  {data.projects.title && ajaxData.projects.filter((i: object) => typeof i === "object").length > 0 && typeof data.projects.title === 'string' && <h3 className='caption-3 detail-works__title detail-works__text'>{data.projects.title}</h3>}
                  <div className='detail-works__projects-list'>
                    {ajaxData.projects.map((elem: any, i: number) => {
                      if (typeof elem === "object") {
                        let images: ImageType["sources"] = [
                          {
                            media: "(min-width: 768px)",
                            srcset: elem.images?.medium || "",
                          },
                          {
                            media: "(min-width: 0)",
                            srcset: elem.images?.small || ""
                          }
                        ];
                        return (
                          <CardRelease {...elem} src={images} href={`/works/${elem.id}`} key={`card-${i}`}/>
                        )
                      }

                    })}
                  </div>
                </section>
              }

              {contentLoaded &&
                <>
                  <LazyLoadImage afterLoad={() => setAnimate('.detail-works__design--tr')} className='detail-works__design detail-works__design--tr' src='/assets/images/loading.png'/>
                  <LazyLoadImage afterLoad={() => setAnimate('.detail-works__design--tl')} className='detail-works__design detail-works__design--tl' src='/assets/images/plane.png'/>
                  <LazyLoadImage afterLoad={() => setAnimate('.detail-works__design--mr')} className='detail-works__design detail-works__design--mr' src='/assets/images/ufo-2.png'/>
                  <LazyLoadImage afterLoad={() => setAnimate('.detail-works__design--ml')} className='detail-works__design detail-works__design--ml' src='/assets/images/austronaut.png'/>
                </>
              }
            </div>
        </div>
    </Suspense>
  )
}

export default DetailWorks;
