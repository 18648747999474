import './style.scss';
import React, {MouseEvent} from 'react';

export type AButtonType = {
    id?: string,
    className?: string,
    text: string,
    type?: string,
    subtext?: string,
    onClick?: (e: MouseEvent) => void,
    onButtonRef?: (ref: HTMLButtonElement | null) => void,
    disabled?: boolean,
}

const AButton = (props: AButtonType) => {
    return (
        <button
            ref={props.onButtonRef}
            className={['button', props.className ? props.className : '', props.type ? `button--${props.type}` : ''].join(' ')}
            onClick={props.onClick}
            id={props.id}
            aria-label={props.text || 'button with text'}
            disabled={props.disabled}
        >
        {props.text && typeof props.text === 'string' && <div className={`button__text ${props.subtext ? 'text-4 text-ln-1' : ''}`}>{props.text}</div>}
        {props.subtext && typeof props.subtext === 'string' && <div className='button__subtext text-3'>{props.subtext}</div>}
        </button>
    );
};

AButton.defaultProps = {
    onButtonRef: () => {},
}

export default AButton;
