import './styles.scss';
import React, {useContext, useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import Sprite from "../../assets/scripts/Sprite";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { transformCard, clearTransform } from '../../assets/scripts/animations';
import { parallaxEffect } from '../../assets/scripts/animations';
import InlineHtmlViewer from "../../components/organisms/inline-html-viewer";
import Breadcrumbs from "../../components/molecules/breadcrubs";
import { SEOTypes } from "../../types";
import { request } from "../../api/api";
import Sharing from "../../components/molecules/sharing";
import useChangeSEO from "../../hooks/useChangeSEO";
import { NavItem } from '../../components/organisms/header';
import { NotFoundContext } from '../../context/NotFoundContext';
import { ImageType } from "../../components/atoms/image";
import fixedUrlTo from "../../assets/scripts/fixedUrlTo";
const dataJSON = require('../../data/detailBlogs.json');
const currentLang = process.env.REACT_APP_LANG as keyof typeof dataJSON;
const data = dataJSON[currentLang];

const CardEvent = React.lazy(() => import('../../components/molecules/card-event'));
const urlByStages = process.env.REACT_APP_STAGE === "integration" ? "getPageBlog/" : '/ajax/blogs-detail.json';

const DetailBlogs = () => {
  const [ajaxData, setAjaxData] = useState<any>({});
  const { article_id } = useParams();
  const [context, setContext] = useContext(NotFoundContext);

  useEffect(() => {
    request({
      method: "GET",
      url: urlByStages,
      params: {
        lang: process.env.REACT_APP_LANG,
        code: article_id
      },
    }).then(response => {
      setAjaxData(response.data);
    }).catch(() => {
        setContext(true);
    });
  }, [article_id]);

  const setAnimate = (elem: string) => {
    parallaxEffect(elem);
  }

  //@ts-ignore
  const partBreadCrumbs = window?.INIT_DATA?.nav?.find((obj: NavItem) => obj?.to === '/blogs')?.text ? window.INIT_DATA.nav.find((obj: NavItem) => obj.to === '/blogs').text : ""

  const breadcrumbsItems = [
    {
        "text": typeof data.breadcrumbs.text ? data.breadcrumbs.text : '',
        "to": "/"
    },
    {
        "text": partBreadCrumbs,
        "to": "/blogs"
    },
    {
        "text": ajaxData.title,
        "to": "#"
    }
  ]
  const seoData: Partial<SEOTypes> = {
    title: ajaxData?.title || "",
    image: ajaxData?.image ? ajaxData?.image[0] === "/" ? window.location.origin + ajaxData.image : ajaxData.image : "",
    ...ajaxData.seo,
  };

  useChangeSEO({data: seoData});

  if (!data) return null;

  return (
    <div className="detail-blogs">
      <span className='gradient-radial gradient-radial--red detail-blogs__gradient detail-blogs__gradient--tl'></span>
      <span className='gradient-radial gradient-radial--red detail-blogs__gradient detail-blogs__gradient--tr'></span>
      <div className="detail-blogs__content">
        <Sprite />
          <div className="detail-blogs__head container">
            <Breadcrumbs items={breadcrumbsItems} />
            {ajaxData.label?.text &&
              <Link className='detail-blogs__label'
                to={fixedUrlTo(`/blogs/?id=${ajaxData.label.group}`)}
              >{ajaxData.label.text}</Link>
            }
            {ajaxData.title && typeof ajaxData.title === 'string' && <h1 className='detail-blogs__title caption-7'>{ajaxData.title}</h1>}
          </div>

          {ajaxData.image && (
            <div className='detail-blogs__image-wrapper container'>
              <LazyLoadImage className="detail-blogs__image" src={ajaxData.image} alt={ajaxData.title} />
            </div>
          )}

          {ajaxData.html && (
            <InlineHtmlViewer
              inlineHTML={ajaxData.html}
              className="detail-blogs__html-content container"
            />
          )}
          <section className="detail-blogs__sharing container">
            <div className="detail-blogs__sharing-head">
              {data.sharing.title && typeof data.sharing.title === 'string' && <span className="detail-blogs__sharing-title">{data.sharing.title}</span>}
              {ajaxData.date && typeof ajaxData.date === 'string' && <span className="detail-blogs__blog-date">{ajaxData.date}</span>}
            </div>
            <Sharing items={data.sharing.items} />
          </section>
          {Array.isArray(ajaxData.article) &&
            <section className='detail-blogs__projects container'>
              {data.article.title && ajaxData.article.filter((i: object) => typeof i === "object").length > 0 && typeof data.article.title === 'string' && <h3 className='caption-3 detail-blogs__projects-title'>{data.article.title}</h3>}
              <div className='detail-blogs__projects-list'>
                {ajaxData.article.map((elem: any, i: number) => {
                  if (typeof elem === "object") {
                    return (
                      <div className='detail-blogs__projects-item' key={i}>
                        <CardEvent
                          key={`card-${i}`}
                          {...elem}
                          src={elem?.images?.medium || ""}
                          to={fixedUrlTo(`/blogs/${elem.id}`)}
                          mouseMove={(e: React.MouseEvent, elem: HTMLElement) => transformCard(e, elem)}
                          mouseLeave={(elem: HTMLElement) => clearTransform(elem)}
                        />
                      </div>
                    )
                  }
                })}
              </div>
            </section>
          }
          <LazyLoadImage afterLoad={() => setAnimate('.detail-blogs__design--tr')} className='detail-blogs__design detail-blogs__design--tr' src='/assets/images/loading.png'/>
          <LazyLoadImage afterLoad={() => setAnimate('.detail-blogs__design--tl')} className='detail-blogs__design detail-blogs__design--tl' src='/assets/images/tesla.png'/>
      </div>
    </div>
  )
}

export default DetailBlogs;
