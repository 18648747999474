import ym from "react-yandex-metrika";

const ymTrigers = {
  phoneNumberClick: () => ym("reachGoal", 'phone_number_click'),
  formSend: () => ym("reachGoal", 'form_send_v2'),
  formClick: () => ym("reachGoal", 'new_coalla_form_click'),
  sendRequest: () => ym('reachGoal','send_request'),
}

export default ymTrigers;
