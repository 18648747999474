import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import mainSlice from "../features/mainSlice";
import worksSlice from "../features/worksSlise";

export const store = configureStore({
  reducer: {
      main: mainSlice.reducer,
      works: worksSlice.reducer,
  },
  middleware: [thunkMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
