import { gsap, ScrollTrigger } from 'gsap/all';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from "classnames";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./style.scss";

gsap.registerPlugin(ScrollTrigger);

export type TeamType = {
    title: string,
    people: {
        item: string,
        img: string,
        name: string,
        description: string,
        sort: boolean
    }[],
}

type TeamItem = {
    item: string,
    img: string,
    name: string,
    description: string,
    sort: boolean
}

const getRandom = (n: number) => Math.floor(Math.random() * n);

const Team = (props: TeamType) => {
    const people = props?.people?.length ? props.people.slice(0, 12) : [];
    const [selectedKey, setSelectedKey] = useState<number>(getRandom(people.length));
    const initTl = useMemo(() => gsap.timeline(), []);

    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 320px)": () => {
                initTl.fromTo('.team__border', {
                    borderRadius: "50%"
                }, {
                    borderRadius: `random(50, 35) random(50, 35) random(50, 35) random(50, 35)`,
                    duration: 0.5,
                    yoyo: true,
                    repeat: -1,
                    repeatRefresh: true,
                    ease: "none"
                });

                initTl.fromTo('.team__border-after', {
                    borderRadius: "50%"
                }, {
                    borderRadius: `random(50, 35) random(50, 35) random(50, 35) random(50, 35)`,
                    duration: 0.5,
                    yoyo: true,
                    repeat: -1,
                    repeatRefresh: true,
                    ease: "none"
                });

                initTl.fromTo('.team__border-before', {
                    borderRadius: "50%"
                }, {
                    borderRadius: `random(50, 35) random(50, 35) random(50, 35) random(50, 35)`,
                    duration: 0.5,
                    yoyo: true,
                    repeat: -1,
                    repeatRefresh: true,
                    ease: "none"
                });

                initTl.fromTo('.team__border', {
                    rotate: 0,
                }, {
                    rotate: 360,
                    duration: 5,
                    repeat: -1,
                    ease: "none"
                }, 0);
            }
        });
    }, [ initTl ]);

    if (!people.length) return null;

    return (
        <div className='team container'>
            <LazyLoadImage
                className='team__rocket'
                src='/assets/images/team-rocket.png'
            />
            <div className="team__planet"></div>
            <div className="team__planet-small"></div>
            {props.title && typeof props.title === 'string' && <div className="team__title caption-3">{props.title}</div>}
            <div className='team__people'>
                {people.map((item, i) =>
                    <div
                        key={`image-${i}`}
                        className={`team__item`}
                        onClick={() => selectedKey !== i && setSelectedKey(i)}
                    >
                        <LazyLoadImage
                            className={`team__image team__image--other-image`}
                            src={item.img} alt='planet'
                        />
                    </div>
                )}
                <div className="team__main-image">
                    <div className={`team__wrapper`}>
                        {people.map((item, n) => (
                            <img
                                loading="lazy"
                                key={`profile-image-${n}`}
                                className={cn(`team__profile-image`, {
                                    "team__profile-image--view": selectedKey === n,
                                })}
                                src={item.img}
                                alt='planet'
                            />
                        ))}
                    </div>
                    <div className="team__border">
                        <div className="team__border-before"></div>
                        <div className="team__border-after"></div>
                    </div>
                </div>
            </div>
            <div className='team__profile'>
                {people.map((item, n) => (
                    <div
                        key={`profile-text-${n}`}
                        className={cn("team__profile-item", {
                            "team__profile-item--view": n === selectedKey,
                        })}
                    >
                        {item.name && <div className="team__name text-5">{item.name}</div>}
                        {item.description && <div className="team__content text-2 ">{item.description}</div>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Team;
