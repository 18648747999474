import './styles.scss';
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import Parser, { domToReact } from 'html-react-parser';
import Swiper, { Navigation } from 'swiper';
import cn from 'classnames';
import { Link } from "react-router-dom";
import debounce from "../../../assets/scripts/debounce";
import fixedUrlTo from "../../../assets/scripts/fixedUrlTo";
import LazyLoad from "react-lazyload";

export type InlineHtmlViewerProps = {
  inlineHTML: string,
  className?: string,
  animation?: () => void,
  onLoadVideo: (e: React.SyntheticEvent<HTMLVideoElement, Event>) => void,
  onLoadImages: (e: React.SyntheticEvent<HTMLImageElement>) => void,
}

const InlineHtmlViewer = ({
  inlineHTML,
  className,
  animation,
  onLoadVideo,
  onLoadImages,
}: InlineHtmlViewerProps) => {
  const containerRef = useRef<null | HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const swipersEl: HTMLElement[] = Array.from(containerRef.current.querySelectorAll(".swiper"));
    if (swipersEl.length) {
      swipersEl.forEach((node: HTMLElement) => {
        new Swiper(node, {
          modules: [Navigation],
          slidesPerView: 1,
          spaceBetween: 16,
          navigation: {
            nextEl: node.querySelector(".swiper-button-next") as HTMLElement,
            prevEl: node.querySelector(".swiper-button-prev") as HTMLElement,
          }
        });
      })
    }
  }, []);

  useLayoutEffect(() => {
    if (animation) {
      animation();
    }
  }, [animation]);

  const changeActionVideo = useCallback((videoEl: HTMLVideoElement) => {
    if (!videoEl) return;
    videoEl.muted = true;
    
    if (Math.round(videoEl?.getBoundingClientRect().top) + window.pageYOffset <= window.pageYOffset + window.innerHeight
    && Math.round(videoEl?.getBoundingClientRect().top) + videoEl.offsetHeight + window.pageYOffset > window.pageYOffset
    ) {
      const isPlaying = videoEl.currentTime > 0 && (videoEl.paused || !videoEl.ended) && videoEl.readyState > videoEl.HAVE_CURRENT_DATA;
      if (videoEl.paused && isPlaying) {
        videoEl.play();
      }
    } else if (Math.round(videoEl?.getBoundingClientRect().top) + videoEl.offsetHeight + window.pageYOffset <= window.pageYOffset) {
      if (!videoEl.paused) {
        videoEl.pause();
      }
    } else {
      if (!videoEl.paused) {
        videoEl.pause();
      }
    }
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;
    const elsAutoVideos = [].slice.call(containerRef.current.querySelectorAll(".autoplay"));
    if (!elsAutoVideos.length) return;
    
    elsAutoVideos.forEach((videoEl: HTMLVideoElement) => {
      changeActionVideo(videoEl);
      window.addEventListener("scroll", () => debounce(changeActionVideo(videoEl), 400));
    })
    return () => {
      elsAutoVideos.forEach((videoEl) => {
        window.removeEventListener("scroll", () => debounce(changeActionVideo(videoEl), 400));
      })
    }
  }, [inlineHTML]);

  return (
    <div className={cn("inline-html-viewer", className)} ref={containerRef}>
      {Parser(inlineHTML, {
        replace: (elems: any) => {
          if (elems?.type === "text" && !elems?.parent && elems?.data) {
            return <p>{elems.data}</p>
          }

          if (elems.name === "table") {
            return <div className="table-wrapper"><table>{domToReact(elems.children)}</table></div>
          }

          if (elems.name === "video" && elems.attribs?.src) {
            const isAytoplay = elems.attribs?.class === "autoplay";
            return (
              <LazyLoad offset={100}>
                <video
                  {...elems.attribs}
                  autoPlay={isAytoplay}
                  loop={isAytoplay}
                  controls={!isAytoplay}
                  preload="metadata"
                  playsInline
                  muted
                  onLoadedMetadata={(e) => {
                    onLoadVideo(e);
                    changeActionVideo(e.currentTarget);
                  }}
                >
                  {domToReact(elems.children)}
                </video>
              </LazyLoad>
            )
          }

          if (elems.name === "img") {
            return (
              <LazyLoad offset={500}>
                <img
                  {...elems.attribs}
                  style={elems.attribs.style || undefined}
                  width="100%"
                  height="100%"
                  alt="contentImage"
                  onLoad={onLoadImages}
                  loading="lazy"
                />
              </LazyLoad>
            )
          }

          if (elems.children && elems.attribs && elems.attribs['href']) {
            if (elems.attribs.href[0] === "/") {
              return (
                <Link
                  to={fixedUrlTo(elems.attribs.href)}
                  target={elems.attribs.href[0] === "/" ? undefined : "_blank"}
                  rel={elems.attribs.href[0] === "/" ? undefined : "noopener noreferrer"}
                >
                  {domToReact(elems.children)}
                </Link>
              )
            }
            if (elems.attribs.href[0] === "#") {
              return (
                <a href={elems.attribs.href}>{domToReact(elems.children)}</a>
              )
            }
            return <a href={elems.attribs.href} target="_blank" rel="noreferrer noopener">{domToReact(elems.children)}</a>
          }
        }
      })}
    </div>
  )
}

InlineHtmlViewer.defaultProps = {
  onLoadVideo: () => {},
  onLoadImages: () => {},
}

export default React.memo(InlineHtmlViewer);
