import {useContext, useState, useEffect} from 'react';
import Sprite from "../../assets/scripts/Sprite";
import './styles.scss';
import InlineHtmlViewer from "../../components/organisms/inline-html-viewer";
import { SEOTypes } from "../../types";
import { request } from "../../api/api";
import useChangeSEO from "../../hooks/useChangeSEO";
import { NotFoundContext } from '../../context/NotFoundContext';
import { useParams } from "react-router-dom";

const urlByStages = process.env.REACT_APP_STAGE === "integration" ? "getStaticPage/" : '/ajax/processing-policy.json';

const StaticPage = () => {
  const { static_code } = useParams();
  const [ajaxData, setAjaxData] = useState<any>({});
  const [context, setContext] = useContext(NotFoundContext);

  useEffect(() => {
    request({
      method: "GET",
      url: urlByStages,
      params: {
        code: static_code,
      },
    }).then(response => {
      setAjaxData(response.data);
    }).catch(() => {
      setContext(true);
    });
  }, []);

  const seoData: Partial<SEOTypes> = {
    title: ajaxData?.title || "",
    image: ajaxData?.image ? ajaxData?.image[0] === "/" ? window.location.origin + ajaxData.image : ajaxData.image : "",
    ...ajaxData.seo,
  };

  useChangeSEO({data: seoData});

  return (
    <>
      <div className="static-page">
        <span className='gradient-radial gradient-radial--red static-page__gradient static-page__gradient--tl'></span>
        <span className='gradient-radial gradient-radial--red static-page__gradient static-page__gradient--tr'></span>
        <Sprite />
        {ajaxData.html && (
          <InlineHtmlViewer
            inlineHTML={ajaxData.html}
            className="static-page__content container"
          />
        )}
      </div>
    </>
  )
}

export default StaticPage;
