import './style.scss';
import React from 'react';
import AIcon from '../../atoms/icon';

export type SocialsType = {
    items: {
        icon: string,
        href: string,
    }[],
    className?: string
}

const Socials = (props: SocialsType) => {
    const classes = ['socials', props.className ? props.className : ''].join(' ');
    return (
        <div className={classes}>
            {Array.isArray(props.items) && props.items.map((item, i) => (
                <a href={item.href} className='socials__item' key={`socials-${i}`} aria-label='social link' target='_blank' rel="noreferrer noopener">
                    <AIcon className='socials__icon' name={item.icon}/>
                </a>
            ))}
        </div>
    );
};

export default Socials;
