import { createSlice } from "@reduxjs/toolkit";
import { request } from "../api/api";
import { AppThunk, RootState } from "../app/store";

type PropsTypes = {
  items: Array<any>,
  idList: Array<string>,
  onError: () => void,
  onSuccess: () => void,
  onItemsLoaded: () => void,
  onLoadedFilters: (data: InitialValueTypes) => void,
  onEndLoadItems?: () => void,
}

type InitialValueTypes = {
  description: string,
  filter: Array<any>,
  items: Array<any>,
  popup_form: {
    title: string,
    subtitle: string,
  },
  seo: Object,
  text: string,
  title: string,
  usedFiltres: Array<string>,
}

const initialValue: InitialValueTypes = {
  description: "",
  filter: [],
  items: [],
  popup_form: {
    title: "",
    subtitle: "",
  },
  seo: {},
  text: "",
  title: "",
  usedFiltres: [],
}

const worksSlice = createSlice({
  name: "works",
  initialState: initialValue,
  reducers: {
    setWorks: (state, action) => {
      const newData = action.payload;
      return { ...state, ...newData }
    },
    addMoreItems: (state, action) => {
      state.items = state.items.concat(action.payload);
    },
    changeUsedFiltres: (state, action) => {
      state.usedFiltres = action.payload;
    }
  }
});

export default worksSlice;

export const { setWorks, addMoreItems, changeUsedFiltres } = worksSlice.actions;
export const getWorksData = (state: RootState) => state.works;
export const urlByStages = process.env.REACT_APP_STAGE === "integration" ? "getPageWorks/" : '/ajax/works.json';

export const loadWorksData = ({
  idList,
  onSuccess,
  onError,
  onItemsLoaded,
  onLoadedFilters
}: Partial<PropsTypes>): AppThunk => (dispatch) => {
  request({
    method: "GET",
    url: urlByStages,
    params: {
        lang: process.env.REACT_APP_LANG,
        id: idList ? idList.join(',') : [],
    },
  }).then(({ data }) => {
    dispatch(changeUsedFiltres(idList ?? []))
    if (onSuccess && typeof onSuccess === "function") {
      onSuccess()
    };

    if (data?.filter) {
      if (onLoadedFilters && typeof onLoadedFilters === "function") {
        onLoadedFilters(data);
      }
    }

    if (data.items?.length) {
      if (onItemsLoaded && typeof onItemsLoaded === "function") {
        onItemsLoaded();
      }
    }

    dispatch(setWorks(data));
  }).catch((err) => {
    console.error(err);
    if (onError && typeof onError === "function") {
      onError();
    }
  });
}

export const loadMoreWorksItem = ({
  items,
  idList,
  onSuccess,
  onEndLoadItems,
}: Partial<PropsTypes>): AppThunk => (dispatch) => {
  if (!idList || !items) return;

  request({
    method: "GET",
    url: urlByStages,
    params: {
        lang: process.env.REACT_APP_LANG,
        count: items.length,
        id: idList.join(',')
    },
  }).then(({ data }) => {
    if (data.items?.length) {
      dispatch(addMoreItems(data.items));
      onSuccess && onSuccess();

      if (items.length === 0) {
        onEndLoadItems && onEndLoadItems();
      }
    }
  });
}
