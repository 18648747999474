import './style.scss';
import React from 'react';
import Parser from 'html-react-parser';
import debounce from '../../../assets/scripts/debounce';
import { useNavigate } from 'react-router-dom';
import Icon from '../../atoms/icon';
import { LazyLoadImage } from "react-lazy-load-image-component";
import fixedUrlTo from "../../../assets/scripts/fixedUrlTo";
import Image, { ImageType } from "../../atoms/image";

export type CardServiceType = {
  id?: string,
  text: string,
  name?: string
  color?: string,
  icon?: string,
  to?: string,
  image?: ImageType["sources"],
  description?: string,
  viewArrow?: boolean,
  className?: string,
  mouseMove?: (e: React.MouseEvent, card: HTMLElement) => void,
  mouseLeave?: (card: HTMLElement) => void,
}

const CardService = (props: CardServiceType) => {
  const navigate = useNavigate();
  const card = React.useRef<HTMLDivElement | null>(null);

  const mouseMove = (e: React.MouseEvent) => {
    if (props.mouseMove) {
      props.mouseMove(e, card.current!)
    }
  }

  const mouseLeave = () => {
    if (props.mouseLeave) {
      props.mouseLeave(card.current!)
    }
  }

  const handlePushToLocation = () => {
    if (props.to) {
      navigate(fixedUrlTo(props.to))
    }
  }

  return (
    <div
      ref={card}
      className={`card-service ${props.className || ''}`}
      style={props.color ? { color: props.color } : {}}
      onMouseMove={(e: any) => debounce(mouseMove(e), 100)}
      onMouseLeave={mouseLeave}
      onClick={handlePushToLocation}
    >
      <div className="card-service__content">
        {props.name && typeof props.name === 'string' && <Icon className='card-service__icon' name={props.name} style={props.color ? { fill: props.color, stroke: props.color } : {}} />}
        {props.icon && typeof props.icon === 'string' && <LazyLoadImage className='card-service__icon' src={props.icon} alt={`icon: ${props.text}`} />}
        {props.text && typeof props.text === 'string' && <span className='card-service__text'>{Parser(props.text)}</span>}
        {props.description && typeof props.description === 'string' && <span className='card-service__description'>{Parser(props.description)}</span>}
        {props.viewArrow &&
          <div className="card-service__arrow-icon-wrapper">
            <Icon className='card-service__arrow-icon' name="arrow" />
          </div>
        }
      </div>
      {props.image && (
        <Image className="card-service__back-image" sources={props.image} />
      )}
    </div>
  );
};

export default CardService;
