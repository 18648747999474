import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from "./app/store";
import initDataJSON from "./data/INIT_DATA.json";
import { YMInitializer } from "react-yandex-metrika";

const rootElement = document.getElementById("root");

if (process.env.REACT_APP_STAGE === 'front' || process.env.NODE_ENV === 'development') {
  (window as Window | any).INIT_DATA = initDataJSON;
}

const templ = <React.StrictMode>
  <CookiesProvider>
    <Provider store={store}>
      <App />
      <YMInitializer accounts={[47283456]} />
    </Provider>
  </CookiesProvider>
</React.StrictMode>

if (rootElement!.hasChildNodes()) {
  ReactDOM.hydrateRoot((rootElement as Element), templ);
} else {
  ReactDOM.createRoot(rootElement as HTMLElement).render(templ);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
